import { casinoTabs } from "./casinoTabs.data";
import { slotsData } from "./slots.data";

export const WordCasinoGames = {
  ...slotsData.oneplaytab.slide1,
  ...slotsData.oneplaytab.slide2,

  ...casinoTabs.Supernowa.slide1,
  ...casinoTabs.Supernowa.slide2,
  ...casinoTabs.Supernowa.slide3,
  ...casinoTabs.Vivo.slide1,
  ...casinoTabs.Vivo.slide2,

  // ...slotsData.onetouchtab.slide1,
  // ...slotsData.onetouchtab.slide2,
  ...slotsData.prgmaticplaytab.slide1,
  ...slotsData.prgmaticplaytab.slide2,
  ...slotsData.prgmaticplaytab.slide3,
  ...slotsData.prgmaticplaytab.slide4,
  ...slotsData.prgmaticplaytab.slide5,
  ...slotsData.prgmaticplaytab.slide6,
  ...slotsData.prgmaticplaytab.slide7,
  ...slotsData.prgmaticplaytab.slide8,
  ...slotsData.prgmaticplaytab.slide9,
  ...slotsData.prgmaticplaytab.slide10,
  ...slotsData.prgmaticplaytab.slide11,
  ...slotsData.prgmaticplaytab.slide12,
  ...slotsData.prgmaticplaytab.slide13,
  ...slotsData.prgmaticplaytab.slide14,
  ...slotsData.prgmaticplaytab.slide15,
  ...slotsData.prgmaticplaytab.slide16,
  ...slotsData.prgmaticplaytab.slide17,
  ...slotsData.prgmaticplaytab.slide18,
  ...slotsData.prgmaticplaytab.slide19,
  ...slotsData.prgmaticplaytab.slide20,

  // ...slotsData.supernowatab.slide1,
  // ...slotsData.supernowatab.slide2,
};
