import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import TelegramIcon from "../assets/svg/TelegramIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/svg/logo_new.png";
import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsnIcon from "../assets/images/games-icon/slots.png";
import { useSelector } from "react-redux";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header beforeheader">
      <div className="top_head">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="middle-part">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link
                            href="/sportsbook?bt-path=%2Flive"
                            className="exchange-nav"
                          >
                            Live Betting
                          </Nav.Link>
                          <Nav.Link href="/" className="exchange-nav">
                            Sportsbook
                          </Nav.Link>
                          <Nav.Link href="/sign-in">Slots</Nav.Link>
                          <Nav.Link href="/sign-in">Casino</Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
                <div className="headerRight">
                  <ul className="social">
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                  </ul>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="button-primary btn_signin"
                  >
                    SIGN IN
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="button-primary btn_signup ms-2"
                  >
                    SIGN UP
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="headMarquee">
        <marquee scrollamount="3">
          <span className="alertMarq">‼️ALERT‼️</span>
          DO NOT PLAY‼️ 12:00 - 12:15 UTC Maintenance Time
        </marquee>
      </div>
    </header>
  );
};

export default Header;
