import { pageUrlGenerator } from "./casinoTabs.data";

export const slotsData = {
  populargametab: {
    slide1: {
      LuckyHalloween: {
        redirectUrl: "/casino/ezugi/LuckyHalloween",
        code: "1100155",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Halloween.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Halloween",
        providerName: "Red Tiger",
      },
      LuckyLittleDevil: {
        redirectUrl: "/casino/ezugi/LuckyLittleDevil",
        code: "1100156",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Little-Devil.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Little Devil",
        providerName: "Red Tiger",
      },
      LuckyValentine: {
        redirectUrl: "/casino/ezugi/LuckyValentine",
        code: "1100157",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Valentine.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Valentine",
        providerName: "Red Tiger",
      },
      LuckyWizard: {
        redirectUrl: "/casino/ezugi/LuckyWizard",
        code: "1100158",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Wizard.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Wizard",
        providerName: "Red Tiger",
      },
      Masquerade: {
        redirectUrl: "/casino/ezugi/Masquerade",
        code: "1100159",
        casino: "ezugi",
        imgUrl: "./images/slots/Masquerade.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Masquerade",
        providerName: "Red Tiger",
      },
      MayanGods: {
        redirectUrl: "/casino/ezugi/MayanGods",
        code: "1100160",
        casino: "ezugi",
        imgUrl: "./images/slots/Mayan-Gods.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mayan Gods",
        providerName: "Red Tiger",
      },
      MegaDragon: {
        redirectUrl: "/casino/ezugi/MegaDragon",
        code: "1100161",
        casino: "ezugi",
        imgUrl: "./images/slots/Mega-Dragon.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mega Dragon",
        providerName: "Red Tiger",
      },
      MegaJade: {
        redirectUrl: "/casino/ezugi/MegaJade",
        code: "1100162",
        casino: "ezugi",
        imgUrl: "./images/slots/Mega-Jade.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mega Jade",
        providerName: "Red Tiger",
      },
      MegaRise: {
        redirectUrl: "/casino/ezugi/MegaRise",
        code: "1100163",
        casino: "ezugi",
        imgUrl: "./images/slots/Mega-Rise.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mega Rise",
        providerName: "Red Tiger",
      },
      MultiplierRiches: {
        redirectUrl: "/casino/ezugi/MultiplierRiches",
        code: "1100164",
        casino: "ezugi",
        imgUrl: "./images/slots/Multiplier-Riches.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Multiplier Riches",
        providerName: "Red Tiger",
      },
      MysteryReelsMegaways: {
        redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
        code: "1100061",
        casino: "ezugi",
        imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mystery Reels Megaways",
        providerName: "Red Tiger",
      },
      DragonFireMegaways: {
        redirectUrl: "/casino/ezugi/DragonFireMegaways",
        code: "1100074",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Fire-Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Fire Megaways",
        providerName: "Red Tiger",
      },
      DazzleMegaways: {
        redirectUrl: "/casino/ezugi/DazzleMegaways",
        code: "1100099",
        casino: "ezugi",
        imgUrl: "./images/slots/Dazzle-Memegaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dazzle Me Megaways",
        providerName: "NetEnt",
      },
    },

    name: "Popular Games",
  },
  newgametab: {
    slide1: {
      AgentRoyale: {
        redirectUrl: "/casino/ezugi/AgentRoyale",
        code: "1100131",
        casino: "ezugi",
        imgUrl: "./images/slots/AgentRoyale.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Agent Royale",
        providerName: "Red Tiger",
      },
      ArcadeBomb: {
        redirectUrl: "/casino/ezugi/ArcadeBomb",
        code: "1100132",
        casino: "ezugi",
        imgUrl: "./images/slots/Arcade-Bomb.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Arcade Bomb",
        providerName: "Red Tiger",
      },
      Atlantis: {
        redirectUrl: "/casino/ezugi/Atlantis",
        code: "1100133",
        casino: "ezugi",
        imgUrl: "./images/slots/Atlantis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Atlantis",
        providerName: "Red Tiger",
      },
      AztecSpins: {
        redirectUrl: "/casino/ezugi/AztecSpins",
        code: "1100134",
        casino: "ezugi",
        imgUrl: "./images/slots/Aztec-Spins.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aztec Spins",
        providerName: "Red Tiger",
      },
      CinderellaBall: {
        redirectUrl: "/casino/ezugi/CinderellaBall",
        code: "1100135",
        casino: "ezugi",
        imgUrl: "./images/slots/Cinderella`s-Ball.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Cinderella`s Ball",
        providerName: "Red Tiger",
      },
      CirquedelaFortune: {
        redirectUrl: "/casino/ezugi/CirquedelaFortune",
        code: "1100136",
        casino: "ezugi",
        imgUrl: "./images/slots/Cirque-de-la-Fortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Cirque de la Fortune",
        providerName: "Red Tiger",
      },
      ClashoftheBeasts: {
        redirectUrl: "/casino/ezugi/ClashoftheBeasts",
        code: "1100137",
        casino: "ezugi",
        imgUrl: "./images/slots/Clash-of-the-Beasts.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Clash of the Beasts",
        providerName: "Red Tiger",
      },
      CrazyGenie: {
        redirectUrl: "/casino/ezugi/CrazyGenie",
        code: "1100138",
        casino: "ezugi",
        imgUrl: "./images/slots/Crazy-Genie.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Crazy Genie",
        providerName: "Red Tiger",
      },
      CrystalMirror: {
        redirectUrl: "/casino/ezugi/CrystalMirror",
        code: "1100139",
        casino: "ezugi",
        imgUrl: "./images/slots/Crystal-Mirror.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Crystal Mirror",
        providerName: "Red Tiger",
      },
      DaVinciMystery: {
        redirectUrl: "/casino/ezugi/DaVinciMystery",
        code: "1100140",
        casino: "ezugi",
        imgUrl: "./images/slots/Da-Vinci`s-Mystery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Da-Vinci`s Mystery",
        providerName: "Red Tiger",
      },
      DevilNumber: {
        redirectUrl: "/casino/ezugi/DevilNumber",
        code: "1100141",
        casino: "ezugi",
        imgUrl: "./images/slots/Devil`s-Number.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Devil`s Number",
        providerName: "Red Tiger",
      },
      DiceDice: {
        redirectUrl: "/casino/ezugi/DiceDice",
        code: "1100142",
        casino: "ezugi",
        imgUrl: "./images/slots/Dice-Dice-Dice.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dice Dice Dice",
        providerName: "Red Tiger",
      },
      FiveStarsPowerReels: {
        redirectUrl: "/casino/ezugi/FiveStarsPowerReels",
        code: "1100144",
        casino: "ezugi",
        imgUrl: "./images/slots/Five-Stars-Power-Reels.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Five Stars Power Reels",
        providerName: "Red Tiger",
      },
      GemsGoneWildPowerReels: {
        redirectUrl: "/casino/ezugi/GemsGoneWildPowerReels",
        code: "1100146",
        casino: "ezugi",
        imgUrl: "./images/slots/Gems-Gone-Wild-Power-Reels.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gems Gone Wild Power Reels",
        providerName: "Red Tiger",
      },
    },

    name: "New Games",
  },

  megawaystabs: {
    slide1: {
      DivinieFortuneMegaways: {
        redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
        code: "1100089",
        casino: "ezugi",
        imgUrl: "./images/slots/divine_fortune_megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Divine Fortune Megaways",
        providerName: "NetEnt",
      },
      FruitShopMegaways: {
        redirectUrl: "/casino/ezugi/FruitShopMegaways",
        code: "1100094",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit_Shop_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop Megaways",
        providerName: "NetEnt",
      },
      ReelKingMegaways: {
        redirectUrl: "/casino/ezugi/ReelKingMegaways",
        code: "1100052",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel_King_Mega.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel King Mega",
        providerName: "NetEnt",
      },
      TwinSpinMegaways: {
        redirectUrl: "/casino/ezugi/TwinSpinMegaways",
        code: "1100039",
        casino: "ezugi",
        imgUrl: "./images/slots/Twin_Spin_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Twin Spin Megaways",
        providerName: "NetEnt",
      },

      MysteryReelsMegaways: {
        redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
        code: "1100165",
        casino: "ezugi",
        imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mystery Reels Megaways",
        providerName: "Red Tiger",
      },
      GonzoMegawaya: {
        redirectUrl: "/casino/ezugi/GonzoMegawaya",
        code: "1100018",
        casino: "ezugi",
        imgUrl: "./images/slots/Gonzo's-Quest-Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gonzo's Quest Megaways",
        providerName: "Red Tiger",
      },
      PiggyRichesMegaways: {
        redirectUrl: "/casino/ezugi/PiggyRichesMegaways",
        code: "1100059",
        casino: "ezugi",

        imgUrl: "./images/slots/Piggy-Riches-Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Piggy Riches Megaways",
        providerName: "Red Tiger",
      },
    },

    name: "Megaways",
  },
  netenttab: {
    slide1: {
      asgardianstones: {
        redirectUrl: "/casino/ezugi/asgardianstones",
        code: "1100096",
        casino: "ezugi",
        imgUrl: "./images/slots/asgardianstones.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Asgardian Stones",
        providerName: "NetEnt",
      },
      secrets: {
        redirectUrl: "/casino/ezugi/secrets",
        code: "1100106",
        casino: "ezugi",
        imgUrl: "./images/slots/secrets-of-atlantis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Secrets of Atlantis",
        providerName: "NetEnt",
      },
      SteamTower: {
        redirectUrl: "/casino/ezugi/SteamTower",
        code: "1100043",
        casino: "ezugi",
        imgUrl: "./images/slots/Steam-Tower.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Steam Tower",
        providerName: "NetEnt",
      },
      DivinieFortuneMegaways: {
        redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
        code: "1100089",
        casino: "ezugi",
        imgUrl: "./images/slots/divine_fortune_megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Divine Fortune Megaways",
        providerName: "NetEnt",
      },
      FruitShopMegaways: {
        redirectUrl: "/casino/ezugi/FruitShopMegaways",
        code: "1100094",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit_Shop_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop Megaways",
        providerName: "NetEnt",
      },
      GorillaKingdom: {
        redirectUrl: "/casino/ezugi/GorillaKingdom",
        code: "1100088",
        casino: "ezugi",
        imgUrl: "./images/slots/Gorilla_Kingdom.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gorilla Kingdom",
        providerName: "NetEnt",
      },
      ReelKingMegaways: {
        redirectUrl: "/casino/ezugi/ReelKingMegaways",
        code: "1100052",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel_King_Mega.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel King Mega",
        providerName: "NetEnt",
      },
      Christmas: {
        redirectUrl: "/casino/ezugi/Christmas",
        code: "1100032",
        casino: "ezugi",
        imgUrl: "./images/slots/Aloha-Christmas.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha Christmas",
        providerName: "NetEnt",
      },
      ClusterPays: {
        redirectUrl: "/casino/ezugi/ClusterPays",
        code: "1100033",
        casino: "ezugi",
        imgUrl: "./images/slots/Aloha-Cluster-Pays.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha Cluster Pays",
        providerName: "NetEnt",
      },
      WildWest: {
        redirectUrl: "/casino/ezugi/WildWest",
        code: "1100038",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild West The Great Train Heist",
        providerName: "NetEnt",
      },
      TwinSpinMegaways: {
        redirectUrl: "/casino/ezugi/TwinSpinMegaways",
        code: "1100039",
        casino: "ezugi",
        imgUrl: "./images/slots/Twin_Spin_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Twin Spin Megaways",
        providerName: "NetEnt",
      },
      TurnFortune: {
        redirectUrl: "/casino/ezugi/TurnFortune",
        code: "1100041",
        casino: "ezugi",
        imgUrl: "./images/slots/Turn_Your_Fortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Turn Your Fortune",
        providerName: "NetEnt",
      },
    },
    slide2: {
      Pyramid: {
        redirectUrl: "/casino/ezugi/Pyramid",
        code: "1100078",
        casino: "ezugi",
        imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Pyramid Quest for Immortality",
        providerName: "NetEnt",
      },
      PiggyRiches: {
        redirectUrl: "/casino/ezugi/PiggyRiches",
        code: "1100080",
        casino: "ezugi",
        imgUrl: "./images/slots/Piggy-Riches.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Piggy Riches",
      },
      DarkKing: {
        redirectUrl: "/casino/ezugi/DarkKing",
        code: "1100081",
        casino: "ezugi",
        imgUrl: "./images/slots/Dark-King-Forbidden-Riches.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dark King Forbidden Riches",
        providerName: "NetEnt",
      },
      JingleSpin: {
        redirectUrl: "/casino/ezugi/JingleSpin",
        code: "1100082",
        casino: "ezugi",
        imgUrl: "./images/slots/Jingle-Spin.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jingle Spin",
        providerName: "NetEnt",
      },
      JackHammer: {
        redirectUrl: "/casino/ezugi/JackHammer",
        code: "1100084",
        casino: "ezugi",
        imgUrl: "./images/slots/Jack-Hammer.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Hammer",
        providerName: "NetEnt",
      },
      DeadorAlive: {
        redirectUrl: "/casino/ezugi/DeadorAlive",
        code: "1100085",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive",
        providerName: "NetEnt",
      },
      DeadorAlive2: {
        redirectUrl: "/casino/ezugi/DeadorAlive2",
        code: "1100087",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive-2.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive 2",
        providerName: "NetEnt",
      },
      JackandtheBeanstalk: {
        redirectUrl: "/casino/ezugi/JackandtheBeanstalk",
        code: "1100086",
        casino: "ezugi",
        imgUrl: "./images/slots/Jack-and-the-Beanstalk.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack and the Beanstalk",
        providerName: "NetEnt",
      },
      Elements: {
        redirectUrl: "/casino/ezugi/Elements",
        code: "1100091",
        casino: "ezugi",
        imgUrl: "./images/slots/Elements-The-Awakening.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Elements The Awakening",
        providerName: "NetEnt",
      },
      FinnandtheSwirlySpin: {
        redirectUrl: "/casino/ezugi/FinnandtheSwirlySpin",
        code: "1100093",
        casino: "ezugi",
        imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Finn and the Swirly Spin",
        providerName: "NetEnt",
      },
      FruitShop: {
        redirectUrl: "/casino/ezugi/FruitShop",
        code: "1100095",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit-Shop.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop",
        providerName: "NetEnt",
      },
      DeadorAlive2FeatureBuy: {
        redirectUrl: "/casino/ezugi/DeadorAlive2FeatureBuy",
        code: "1100100",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive 2 Feature Buy",
        providerName: "NetEnt",
      },
    },

    name: "Netent",
  },
  redtigertab: {
    slide1: {
      NinjaWays: {
        redirectUrl: "/casino/ezugi/NinjaWays",
        code: "1100166",
        casino: "ezugi",
        imgUrl: "./images/slots/Ninja-Ways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Ninja Ways",
        providerName: "Red Tiger",
      },
      PathofDestiny: {
        redirectUrl: "/casino/ezugi/PathofDestiny",
        code: "1100167",
        casino: "ezugi",
        imgUrl: "./images/slots/Path-of-Destiny.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Path of Destiny",
        providerName: "Red Tiger",
      },
      PersianFortune: {
        redirectUrl: "/casino/ezugi/PersianFortune",
        code: "1100168",
        casino: "ezugi",
        imgUrl: "./images/slots/Persian-Fortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Persian Fortune",
        providerName: "Red Tiger",
      },
      ReelHeist: {
        redirectUrl: "/casino/ezugi/ReelHeist",
        code: "1100169",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel-Heist.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel Heist",
        providerName: "Red Tiger",
      },
      RobinHoodWildForest: {
        redirectUrl: "/casino/ezugi/RobinHoodWildForest",
        code: "1100170",
        casino: "ezugi",
        imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Robin Hood`s Wild Forest",
        providerName: "Red Tiger",
      },
      SnowWild: {
        redirectUrl: "/casino/ezugi/SnowWild",
        code: "1100171",
        casino: "ezugi",
        imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Snow Wild And The 7 Features",
        providerName: "Red Tiger",
      },
      SylvanSpirits: {
        redirectUrl: "/casino/ezugi/SylvanSpirits",
        code: "1100172",
        casino: "ezugi",
        imgUrl: "./images/slots/Sylvan-Spirits.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Sylvan Spirits",
        providerName: "Red Tiger",
      },
      TheGreatestTrainRobbery: {
        redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
        code: "1100173",
        casino: "ezugi",
        imgUrl: "./images/slots/The-Greatest-Train-Robbery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Greatest Train Robbery",
        providerName: "Red Tiger",
      },
      TheWildHatter: {
        redirectUrl: "/casino/ezugi/TheWildHatter",
        code: "1100174",
        casino: "ezugi",
        imgUrl: "./images/slots/The-Wild-Hatter.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Wild Hatter",
        providerName: "Red Tiger",
      },
      ThorLightning: {
        redirectUrl: "/casino/ezugi/ThorLightning",
        code: "1100175",
        casino: "ezugi",
        imgUrl: "./images/slots/Thor`s-Lightning.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Thor`s Lightning",
        providerName: "Red Tiger",
      },
      DragonLuck: {
        redirectUrl: "/casino/ezugi/DragonLuck",
        code: "1100001",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck",
        providerName: "Red Tiger",
      },
      DragonLuckReel: {
        redirectUrl: "/casino/ezugi/DragonLuckReel",
        code: "1100002",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Power Reels",
        providerName: "Red Tiger",
      },
    },
    slide2: {
      TikiFruits: {
        redirectUrl: "/casino/ezugi/TikiFruits",
        code: "1100176",
        casino: "ezugi",
        imgUrl: "./images/slots/Tiki-Fruits.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Tiki Fruits",
        providerName: "Red Tiger",
      },
      TotemLightning: {
        redirectUrl: "/casino/ezugi/TotemLightning",
        code: "1100177",
        casino: "ezugi",
        imgUrl: "./images/slots/Totem-Lightning.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Totem Lightning",
        providerName: "Red Tiger",
      },
      VaultCracker: {
        redirectUrl: "/casino/ezugi/VaultCracker",
        code: "1100178",
        casino: "ezugi",
        imgUrl: "./images/slots/Vault-Cracker.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault Cracker",
        providerName: "Red Tiger",
      },
      VaultofAnubis: {
        redirectUrl: "/casino/ezugi/VaultofAnubis",
        code: "1100179",
        casino: "ezugi",
        imgUrl: "./images/slots/Vault-of-Anubis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault of Anubis",
        providerName: "Red Tiger",
      },
      WellofWishes: {
        redirectUrl: "/casino/ezugi/WellofWishes",
        code: "1100180",
        casino: "ezugi",
        imgUrl: "./images/slots/Well-of-Wishes.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Well of Wishes",
        providerName: "Red Tiger",
      },

      WildCatsMultiline: {
        redirectUrl: "/casino/ezugi/WildCatsMultiline",
        code: "1100181",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Cats-Multiline.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Cats Multiline",
        providerName: "Red Tiger",
      },
      WildOClock: {
        redirectUrl: "/casino/ezugi/WildOClock",
        code: "1100182",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-O`Clock.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild O`Clock",
        providerName: "Red Tiger",
      },
      WildChest: {
        redirectUrl: "/casino/ezugi/WildChest",
        code: "1100183",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Wild-Chest.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Wild Chest",
        providerName: "Red Tiger",
      },
      WinEscalator: {
        redirectUrl: "/casino/ezugi/WinEscalator",
        code: "1100184",
        casino: "ezugi",
        imgUrl: "./images/slots/Win-Escalator.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Win Escalator",
        providerName: "Red Tiger",
      },
      YucatanMystery: {
        redirectUrl: "/casino/ezugi/YucatanMystery",
        code: "1100185",
        casino: "ezugi",
        imgUrl: "./images/slots/Yucatan`s-Mystery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Yucatan`s Mystery",
        providerName: "Red Tiger",
      },
      DragonFire: {
        redirectUrl: "/casino/ezugi/DragonFire",
        code: "1100008",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Fire.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Fire",
        providerName: "Red Tiger",
      },
      DragonLuckDeluxe: {
        redirectUrl: "/casino/ezugi/DragonLuckDeluxe",
        code: "1100011",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck-Deluxe.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Deluxe",
        providerName: "Red Tiger",
      },
    },
    name: "Red Tiger",
  },
  oneplaytab: {
    slide1: {
      BookOfBamboo: {
        redirectUrl: "/casino/worldcasino/BookOfBamboo",
        code: "bookofbamboo",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/BookOfBamboo.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Traveling Treasure Africa",
        providerName: "Only Play",
      },
      BookOfEye: {
        redirectUrl: "/casino/worldcasino/BookOfEye",
        code: "bookofeye",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/BookOfEye.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Book of Eye",
        providerName: "Only Play",
      },
      chervonakalyna: {
        redirectUrl: "/casino/worldcasino/chervonakalyna",
        code: "chervonakalyna",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/chervonakalyna.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Chervona Kalyna",
        providerName: "Only Play",
      },
      F777Fighter: {
        redirectUrl: "/casino/worldcasino/F777Fighter",
        code: "fighter",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/F777Fighter.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "F777 Fighter",
        providerName: "Only Play",
      },
      FruityBook: {
        redirectUrl: "/casino/worldcasino/FruityBook",
        code: "fruitybook",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/FruityBook.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruity Book",
        providerName: "Only Play",
      },
      GoldenClover: {
        redirectUrl: "/casino/worldcasino/GoldenClover",
        code: "goldenclover",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/GoldenClover.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Golden Clover",
        providerName: "Only Play",
      },
      HOtAndSpicy: {
        redirectUrl: "/casino/worldcasino/HOtAndSpicy",
        code: "hotandspicy",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/HOtAndSpicy.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Hot and Spicy",
        providerName: "Only Play",
      },
      HOtAndSpicyjackpot: {
        redirectUrl: "/casino/worldcasino/HOtAndSpicyjackpot",
        code: "hotandspicyjackpot",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/HOtAndSpicyjackpot.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Hot and Spicy Jackpot",
        providerName: "Only Play",
      },
      IggyRacing: {
        redirectUrl: "/casino/worldcasino/IggyRacing",
        code: "iggyracing",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/IggyRacing.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Iggy Racingt",
        providerName: "Only Play",
      },
      Incason: {
        redirectUrl: "/casino/worldcasino/Incason",
        code: "incason",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/Incason.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Incason",
        providerName: "Only Play",
      },
      Jackpotter: {
        redirectUrl: "/casino/worldcasino/Jackpotter",
        code: "jackpotter",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/Jackpotter.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Potter",
        providerName: "Only Play",
      },
      jackpotterdeluxe: {
        redirectUrl: "/casino/worldcasino/jackpotterdeluxe",
        code: "jackpotterdeluxe",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/jackpotterdeluxe.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Potter Deluxe",
        providerName: "Only Play",
      },
    },
    slide2: {
      jackpotterxmas: {
        redirectUrl: "/casino/worldcasino/jackpotterxmas",
        code: "jackpotterxmas",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/jackpotterxmas.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Potter X-MAS",
        providerName: "Only Play",
      },
      JokerCoin: {
        redirectUrl: "/casino/worldcasino/JokerCoin",
        code: "jokerscoins",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JokerCoin.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Joker Coin",
        providerName: "Only Play",
      },
      JokerCoinXmas: {
        redirectUrl: "/casino/worldcasino/JokerCoinXmas",
        code: "jokerscoinsxmas",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JokerCoinXmas.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Joker Coin Xmas",
        providerName: "Only Play",
      },
      JuicyCrush: {
        redirectUrl: "/casino/worldcasino/JuicyCrush",
        code: "juicycrush",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JuicyCrush.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Juicy Crush",
        providerName: "Only Play",
      },
      JungleGold: {
        redirectUrl: "/casino/worldcasino/JungleGold",
        code: "junglegold",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JungleGold.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jungle Gold",
        providerName: "Only Play",
      },
      limbocat: {
        redirectUrl: "/casino/worldcasino/limbocat",
        code: "limbocat",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/limbocat.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Limbo Cat",
        providerName: "Only Play",
      },
      LuckyClover: {
        redirectUrl: "/casino/worldcasino/LuckyClover",
        code: "luckyclover",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyClover.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Clover",
        providerName: "Only Play",
      },
      LuckyOcean: {
        redirectUrl: "/casino/worldcasino/LuckyOcean",
        code: "luckyocean",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyOcean.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Ocean",
        providerName: "Only Play",
      },
      LuckyPunch: {
        redirectUrl: "/casino/worldcasino/LuckyPunch",
        code: "luckypunch",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyPunch.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Punch",
        providerName: "Only Play",
      },
      LuckyTanks: {
        redirectUrl: "/casino/worldcasino/LuckyTanks",
        code: "luckytanks",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyTanks.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Tanks",
        providerName: "Only Play",
      },
      Mythsofbastet: {
        redirectUrl: "/casino/worldcasino/Mythsofbastet",
        code: "mythsofbastet",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/Mythsofbastet.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Myths of Bastet",
        providerName: "Only Play",
      },
      RoyalKitties: {
        redirectUrl: "/casino/worldcasino/RoyalKitties",
        code: "royalkitties",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/RoyalKitties.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Royal Kitties",
        providerName: "Only Play",
      },
    },

    name: "Only Play",
  },

  // TVShows: {
  //   slide1: {
  //     Strike777: {
  //       redirectUrl: "/casino/ezugi/Strike777",
  //       code: "1100031",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/777strike.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "777 Strike",
  //       providerName: "Red Tiger",
  //     },
  //     Trillionaire: {
  //       redirectUrl: "/casino/ezugi/Trillionaire",
  //       code: "1100047",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Trillionaire.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Trillionaire",
  //       providerName: "Red Tiger",
  //     },
  //     TreasureMine: {
  //       redirectUrl: "/casino/ezugi/TreasureMine",
  //       code: "1100048",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Treasure-Mine.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Treasure Mine",
  //       providerName: "Red Tiger",
  //     },

  //     RegalStreak: {
  //       redirectUrl: "/casino/ezugi/RegalStreak",
  //       code: "1100050",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Regal-Streak.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Regal Streak",
  //       providerName: "Red Tiger",
  //     },
  //     RegalBeasts: {
  //       redirectUrl: "/casino/ezugi/RegalBeasts",
  //       code: "1100051",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Regal-Beasts.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Regal Beasts",
  //       providerName: "Red Tiger",
  //     },
  //     ReelKeeper: {
  //       redirectUrl: "/casino/ezugi/ReelKeeper",
  //       code: "1100053",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Reel-Keeper.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Reel Keeper",
  //       providerName: "Red Tiger",
  //     },
  //     Victorious: {
  //       redirectUrl: "/casino/ezugi/Victorious",
  //       code: "1100123",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Victorious.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Victorious",
  //       providerName: "NetEnt",
  //     },
  //     Warlords: {
  //       redirectUrl: "/casino/ezugi/Warlords",
  //       code: "1100125",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Warlords-Crystal-of-Power.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Warlords Crystal of Power",
  //       providerName: "NetEnt",
  //     },
  //     WildWater: {
  //       redirectUrl: "/casino/ezugi/WildWater",
  //       code: "1100127",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Wild-Water.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Wild Water",
  //       providerName: "NetEnt",
  //     },
  //     WildWater: {
  //       redirectUrl: "/casino/ezugi/WildWater",
  //       code: "1100127",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Wild-Water.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Wild Water",
  //       providerName: "NetEnt",
  //     },
  //     LegendofAthena: {
  //       redirectUrl: "/casino/ezugi/LegendofAthena",
  //       code: "1100154",
  //       casino: "ezugi",
  //       imgUrl: "./images/slots/Legend-of-Athena.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Legend of Athena",
  //       providerName: "Red Tiger",
  //     },
  //   },

  //   name: "TV Games",
  // },

  AllGames: {
    slide1: {
      LuckyHalloween: {
        redirectUrl: "/casino/ezugi/LuckyHalloween",
        code: "1100155",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Halloween.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Halloween",
        providerName: "Red Tiger",
      },

      LuckyValentine: {
        redirectUrl: "/casino/ezugi/LuckyValentine",
        code: "1100157",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Valentine.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Valentine",
        providerName: "Red Tiger",
      },
      LuckyWizard: {
        redirectUrl: "/casino/ezugi/LuckyWizard",
        code: "1100158",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Wizard.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Wizard",
        providerName: "Red Tiger",
      },
      Masquerade: {
        redirectUrl: "/casino/ezugi/Masquerade",
        code: "1100159",
        casino: "ezugi",
        imgUrl: "./images/slots/Masquerade.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Masquerade",
        providerName: "Red Tiger",
      },
      MayanGods: {
        redirectUrl: "/casino/ezugi/MayanGods",
        code: "1100160",
        casino: "ezugi",
        imgUrl: "./images/slots/Mayan-Gods.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mayan Gods",
        providerName: "Red Tiger",
      },
      MegaDragon: {
        redirectUrl: "/casino/ezugi/MegaDragon",
        code: "1100161",
        casino: "ezugi",
        imgUrl: "./images/slots/Mega-Dragon.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mega Dragon",
        providerName: "Red Tiger",
      },
      MegaJade: {
        redirectUrl: "/casino/ezugi/MegaJade",
        code: "1100162",
        casino: "ezugi",
        imgUrl: "./images/slots/Mega-Jade.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mega Jade",
        providerName: "Red Tiger",
      },
      MegaRise: {
        redirectUrl: "/casino/ezugi/MegaRise",
        code: "1100163",
        casino: "ezugi",
        imgUrl: "./images/slots/Mega-Rise.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mega Rise",
        providerName: "Red Tiger",
      },
      MultiplierRiches: {
        redirectUrl: "/casino/ezugi/MultiplierRiches",
        code: "1100164",
        casino: "ezugi",
        imgUrl: "./images/slots/Multiplier-Riches.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Multiplier Riches",
        providerName: "Red Tiger",
      },
      MysteryReelsMegaways: {
        redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
        code: "1100061",
        casino: "ezugi",
        imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mystery Reels Megaways",
        providerName: "Red Tiger",
      },
      DragonFireMegaways: {
        redirectUrl: "/casino/ezugi/DragonFireMegaways",
        code: "1100074",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Fire-Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Fire Megaways",
        providerName: "Red Tiger",
      },
      DazzleMegaways: {
        redirectUrl: "/casino/ezugi/DazzleMegaways",
        code: "1100099",
        casino: "ezugi",
        imgUrl: "./images/slots/Dazzle-Memegaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dazzle Me Megaways",
        providerName: "NetEnt",
      },
    },
    slide2: {
      AgentRoyale: {
        redirectUrl: "/casino/ezugi/AgentRoyale",
        code: "1100131",
        casino: "ezugi",
        imgUrl: "./images/slots/AgentRoyale.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Agent Royale",
        providerName: "Red Tiger",
      },
      ArcadeBomb: {
        redirectUrl: "/casino/ezugi/ArcadeBomb",
        code: "1100132",
        casino: "ezugi",
        imgUrl: "./images/slots/Arcade-Bomb.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Arcade Bomb",
        providerName: "Red Tiger",
      },
      Atlantis: {
        redirectUrl: "/casino/ezugi/Atlantis",
        code: "1100133",
        casino: "ezugi",
        imgUrl: "./images/slots/Atlantis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Atlantis",
        providerName: "Red Tiger",
      },
      AztecSpins: {
        redirectUrl: "/casino/ezugi/AztecSpins",
        code: "1100134",
        casino: "ezugi",
        imgUrl: "./images/slots/Aztec-Spins.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aztec Spins",
        providerName: "Red Tiger",
      },
      CinderellaBall: {
        redirectUrl: "/casino/ezugi/CinderellaBall",
        code: "1100135",
        casino: "ezugi",
        imgUrl: "./images/slots/Cinderella`s-Ball.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Cinderella`s Ball",
        providerName: "Red Tiger",
      },
      CirquedelaFortune: {
        redirectUrl: "/casino/ezugi/CirquedelaFortune",
        code: "1100136",
        casino: "ezugi",
        imgUrl: "./images/slots/Cirque-de-la-Fortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Cirque de la Fortune",
        providerName: "Red Tiger",
      },
      ClashoftheBeasts: {
        redirectUrl: "/casino/ezugi/ClashoftheBeasts",
        code: "1100137",
        casino: "ezugi",
        imgUrl: "./images/slots/Clash-of-the-Beasts.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Clash of the Beasts",
        providerName: "Red Tiger",
      },
      CrazyGenie: {
        redirectUrl: "/casino/ezugi/CrazyGenie",
        code: "1100138",
        casino: "ezugi",
        imgUrl: "./images/slots/Crazy-Genie.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Crazy Genie",
        providerName: "Red Tiger",
      },
      CrystalMirror: {
        redirectUrl: "/casino/ezugi/CrystalMirror",
        code: "1100139",
        casino: "ezugi",
        imgUrl: "./images/slots/Crystal-Mirror.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Crystal Mirror",
        providerName: "Red Tiger",
      },
      DaVinciMystery: {
        redirectUrl: "/casino/ezugi/DaVinciMystery",
        code: "1100140",
        casino: "ezugi",
        imgUrl: "./images/slots/Da-Vinci`s-Mystery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Da-Vinci`s Mystery",
        providerName: "Red Tiger",
      },
      DevilNumber: {
        redirectUrl: "/casino/ezugi/DevilNumber",
        code: "1100141",
        casino: "ezugi",
        imgUrl: "./images/slots/Devil`s-Number.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Devil`s Number",
        providerName: "Red Tiger",
      },
      DiceDice: {
        redirectUrl: "/casino/ezugi/DiceDice",
        code: "1100142",
        casino: "ezugi",
        imgUrl: "./images/slots/Dice-Dice-Dice.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dice Dice Dice",
        providerName: "Red Tiger",
      },
    },
    slide3: {
      asgardianstones: {
        redirectUrl: "/casino/ezugi/asgardianstones",
        code: "1100096",
        casino: "ezugi",
        imgUrl: "./images/slots/asgardianstones.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Asgardian Stones",
        providerName: "NetEnt",
      },
      ReelKingMegaways: {
        redirectUrl: "/casino/ezugi/ReelKingMegaways",
        code: "1100052",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel_King_Mega.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel King Mega",
        providerName: "NetEnt",
      },
      LuckyLittleDevil: {
        redirectUrl: "/casino/ezugi/LuckyLittleDevil",
        code: "1100156",
        casino: "ezugi",
        imgUrl: "./images/slots/Lucky-Little-Devil.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Little Devil",
        providerName: "Red Tiger",
      },
      TwinSpinMegaways: {
        redirectUrl: "/casino/ezugi/TwinSpinMegaways",
        code: "1100039",
        casino: "ezugi",
        imgUrl: "./images/slots/Twin_Spin_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Twin Spin Megaways",
        providerName: "NetEnt",
      },
      FiveStarsPowerReels: {
        redirectUrl: "/casino/ezugi/FiveStarsPowerReels",
        code: "1100144",
        casino: "ezugi",
        imgUrl: "./images/slots/Five-Stars-Power-Reels.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Five Stars Power Reels",
        providerName: "Red Tiger",
      },
      GemsGoneWildPowerReels: {
        redirectUrl: "/casino/ezugi/GemsGoneWildPowerReels",
        code: "1100146",
        casino: "ezugi",
        imgUrl: "./images/slots/Gems-Gone-Wild-Power-Reels.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gems Gone Wild Power Reels",
        providerName: "Red Tiger",
      },
      MysteryReelsMegaways: {
        redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
        code: "1100165",
        casino: "ezugi",
        imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Mystery Reels Megaways",
        providerName: "Red Tiger",
      },
      GonzoMegawaya: {
        redirectUrl: "/casino/ezugi/GonzoMegawaya",
        code: "1100018",
        casino: "ezugi",
        imgUrl: "./images/slots/Gonzo's-Quest-Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gonzo's Quest Megaways",
        providerName: "Red Tiger",
      },
      PiggyRichesMegaways: {
        redirectUrl: "/casino/ezugi/PiggyRichesMegaways",
        code: "1100059",
        casino: "ezugi",

        imgUrl: "./images/slots/Piggy-Riches-Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Piggy Riches Megaways",
        providerName: "Red Tiger",
      },

      secrets: {
        redirectUrl: "/casino/ezugi/secrets",
        code: "1100106",
        casino: "ezugi",
        imgUrl: "./images/slots/secrets-of-atlantis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Secrets of Atlantis",
        providerName: "NetEnt",
      },
      SteamTower: {
        redirectUrl: "/casino/ezugi/SteamTower",
        code: "1100043",
        casino: "ezugi",
        imgUrl: "./images/slots/Steam-Tower.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Steam Tower",
        providerName: "NetEnt",
      },
      DivinieFortuneMegaways: {
        redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
        code: "1100089",
        casino: "ezugi",
        imgUrl: "./images/slots/divine_fortune_megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Divine Fortune Megaways",
        providerName: "NetEnt",
      },
    },
    slide4: {
      Pyramid: {
        redirectUrl: "/casino/ezugi/Pyramid",
        code: "1100078",
        casino: "ezugi",
        imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Pyramid Quest for Immortality",
        providerName: "NetEnt",
      },
      PiggyRiches: {
        redirectUrl: "/casino/ezugi/PiggyRiches",
        code: "1100080",
        casino: "ezugi",
        imgUrl: "./images/slots/Piggy-Riches.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Piggy Riches",
      },
      DarkKing: {
        redirectUrl: "/casino/ezugi/DarkKing",
        code: "1100081",
        casino: "ezugi",
        imgUrl: "./images/slots/Dark-King-Forbidden-Riches.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dark King Forbidden Riches",
        providerName: "NetEnt",
      },
      JingleSpin: {
        redirectUrl: "/casino/ezugi/JingleSpin",
        code: "1100082",
        casino: "ezugi",
        imgUrl: "./images/slots/Jingle-Spin.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jingle Spin",
        providerName: "NetEnt",
      },
      FruitShopMegaways: {
        redirectUrl: "/casino/ezugi/FruitShopMegaways",
        code: "1100094",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit_Shop_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop Megaways",
        providerName: "NetEnt",
      },
      GorillaKingdom: {
        redirectUrl: "/casino/ezugi/GorillaKingdom",
        code: "1100088",
        casino: "ezugi",
        imgUrl: "./images/slots/Gorilla_Kingdom.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gorilla Kingdom",
        providerName: "NetEnt",
      },
      ReelKingMegaways: {
        redirectUrl: "/casino/ezugi/ReelKingMegaways",
        code: "1100052",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel_King_Mega.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel King Mega",
        providerName: "NetEnt",
      },
      Christmas: {
        redirectUrl: "/casino/ezugi/Christmas",
        code: "1100032",
        casino: "ezugi",
        imgUrl: "./images/slots/Aloha-Christmas.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha Christmas",
        providerName: "NetEnt",
      },
      ClusterPays: {
        redirectUrl: "/casino/ezugi/ClusterPays",
        code: "1100033",
        casino: "ezugi",
        imgUrl: "./images/slots/Aloha-Cluster-Pays.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha Cluster Pays",
        providerName: "NetEnt",
      },
      WildWest: {
        redirectUrl: "/casino/ezugi/WildWest",
        code: "1100038",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild West The Great Train Heist",
        providerName: "NetEnt",
      },
      TwinSpinMegaways: {
        redirectUrl: "/casino/ezugi/TwinSpinMegaways",
        code: "1100039",
        casino: "ezugi",
        imgUrl: "./images/slots/Twin_Spin_Megaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Twin Spin Megaways",
        providerName: "NetEnt",
      },
      TurnFortune: {
        redirectUrl: "/casino/ezugi/TurnFortune",
        code: "1100041",
        casino: "ezugi",
        imgUrl: "./images/slots/Turn_Your_Fortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Turn Your Fortune",
        providerName: "NetEnt",
      },
    },
    slide5: {
      JackHammer: {
        redirectUrl: "/casino/ezugi/JackHammer",
        code: "1100084",
        casino: "ezugi",
        imgUrl: "./images/slots/Jack-Hammer.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Hammer",
        providerName: "NetEnt",
      },
      DeadorAlive: {
        redirectUrl: "/casino/ezugi/DeadorAlive",
        code: "1100085",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive",
        providerName: "NetEnt",
      },
      DeadorAlive2: {
        redirectUrl: "/casino/ezugi/DeadorAlive2",
        code: "1100087",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive-2.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive 2",
        providerName: "NetEnt",
      },
      JackandtheBeanstalk: {
        redirectUrl: "/casino/ezugi/JackandtheBeanstalk",
        code: "1100086",
        casino: "ezugi",
        imgUrl: "./images/slots/Jack-and-the-Beanstalk.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack and the Beanstalk",
        providerName: "NetEnt",
      },
      Elements: {
        redirectUrl: "/casino/ezugi/Elements",
        code: "1100091",
        casino: "ezugi",
        imgUrl: "./images/slots/Elements-The-Awakening.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Elements The Awakening",
        providerName: "NetEnt",
      },
      FinnandtheSwirlySpin: {
        redirectUrl: "/casino/ezugi/FinnandtheSwirlySpin",
        code: "1100093",
        casino: "ezugi",
        imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Finn and the Swirly Spin",
        providerName: "NetEnt",
      },
      FruitShop: {
        redirectUrl: "/casino/ezugi/FruitShop",
        code: "1100095",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit-Shop.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop",
        providerName: "NetEnt",
      },
      DeadorAlive2FeatureBuy: {
        redirectUrl: "/casino/ezugi/DeadorAlive2FeatureBuy",
        code: "1100100",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive 2 Feature Buy",
        providerName: "NetEnt",
      },
      NinjaWays: {
        redirectUrl: "/casino/ezugi/NinjaWays",
        code: "1100166",
        casino: "ezugi",
        imgUrl: "./images/slots/Ninja-Ways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Ninja Ways",
        providerName: "Red Tiger",
      },
      PathofDestiny: {
        redirectUrl: "/casino/ezugi/PathofDestiny",
        code: "1100167",
        casino: "ezugi",
        imgUrl: "./images/slots/Path-of-Destiny.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Path of Destiny",
        providerName: "Red Tiger",
      },
      PersianFortune: {
        redirectUrl: "/casino/ezugi/PersianFortune",
        code: "1100168",
        casino: "ezugi",
        imgUrl: "./images/slots/Persian-Fortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Persian Fortune",
        providerName: "Red Tiger",
      },
      ReelHeist: {
        redirectUrl: "/casino/ezugi/ReelHeist",
        code: "1100169",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel-Heist.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel Heist",
        providerName: "Red Tiger",
      },
    },
    slide6: {
      RobinHoodWildForest: {
        redirectUrl: "/casino/ezugi/RobinHoodWildForest",
        code: "1100170",
        casino: "ezugi",
        imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Robin Hood`s Wild Forest",
        providerName: "Red Tiger",
      },
      SnowWild: {
        redirectUrl: "/casino/ezugi/SnowWild",
        code: "1100171",
        casino: "ezugi",
        imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Snow Wild And The 7 Features",
        providerName: "Red Tiger",
      },
      SylvanSpirits: {
        redirectUrl: "/casino/ezugi/SylvanSpirits",
        code: "1100172",
        casino: "ezugi",
        imgUrl: "./images/slots/Sylvan-Spirits.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Sylvan Spirits",
        providerName: "Red Tiger",
      },
      TheGreatestTrainRobbery: {
        redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
        code: "1100173",
        casino: "ezugi",
        imgUrl: "./images/slots/The-Greatest-Train-Robbery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Greatest Train Robbery",
        providerName: "Red Tiger",
      },
      TheWildHatter: {
        redirectUrl: "/casino/ezugi/TheWildHatter",
        code: "1100174",
        casino: "ezugi",
        imgUrl: "./images/slots/The-Wild-Hatter.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Wild Hatter",
        providerName: "Red Tiger",
      },

      DragonLuck: {
        redirectUrl: "/casino/ezugi/DragonLuck",
        code: "1100001",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck",
        providerName: "Red Tiger",
      },
      DragonLuckReel: {
        redirectUrl: "/casino/ezugi/DragonLuckReel",
        code: "1100002",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Power Reels",
        providerName: "Red Tiger",
      },
      TikiFruits: {
        redirectUrl: "/casino/ezugi/TikiFruits",
        code: "1100176",
        casino: "ezugi",
        imgUrl: "./images/slots/Tiki-Fruits.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Tiki Fruits",
        providerName: "Red Tiger",
      },
      TotemLightning: {
        redirectUrl: "/casino/ezugi/TotemLightning",
        code: "1100177",
        casino: "ezugi",
        imgUrl: "./images/slots/Totem-Lightning.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Totem Lightning",
        providerName: "Red Tiger",
      },
      VaultCracker: {
        redirectUrl: "/casino/ezugi/VaultCracker",
        code: "1100178",
        casino: "ezugi",
        imgUrl: "./images/slots/Vault-Cracker.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault Cracker",
        providerName: "Red Tiger",
      },
      VaultofAnubis: {
        redirectUrl: "/casino/ezugi/VaultofAnubis",
        code: "1100179",
        casino: "ezugi",
        imgUrl: "./images/slots/Vault-of-Anubis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault of Anubis",
        providerName: "Red Tiger",
      },
    },
    slide7: {
      WellofWishes: {
        redirectUrl: "/casino/ezugi/WellofWishes",
        code: "1100180",
        casino: "ezugi",
        imgUrl: "./images/slots/Well-of-Wishes.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Well of Wishes",
        providerName: "Red Tiger",
      },

      WildCatsMultiline: {
        redirectUrl: "/casino/ezugi/WildCatsMultiline",
        code: "1100181",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Cats-Multiline.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Cats Multiline",
        providerName: "Red Tiger",
      },
      WildOClock: {
        redirectUrl: "/casino/ezugi/WildOClock",
        code: "1100182",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-O`Clock.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild O`Clock",
        providerName: "Red Tiger",
      },
      WildChest: {
        redirectUrl: "/casino/ezugi/WildChest",
        code: "1100183",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Wild-Chest.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Wild Chest",
        providerName: "Red Tiger",
      },
      WinEscalator: {
        redirectUrl: "/casino/ezugi/WinEscalator",
        code: "1100184",
        casino: "ezugi",
        imgUrl: "./images/slots/Win-Escalator.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Win Escalator",
        providerName: "Red Tiger",
      },
      YucatanMystery: {
        redirectUrl: "/casino/ezugi/YucatanMystery",
        code: "1100185",
        casino: "ezugi",
        imgUrl: "./images/slots/Yucatan`s-Mystery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Yucatan`s Mystery",
        providerName: "Red Tiger",
      },
      DragonFire: {
        redirectUrl: "/casino/ezugi/DragonFire",
        code: "1100008",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Fire.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Fire",
        providerName: "Red Tiger",
      },
      DragonLuckDeluxe: {
        redirectUrl: "/casino/ezugi/DragonLuckDeluxe",
        code: "1100011",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck-Deluxe.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Deluxe",
        providerName: "Red Tiger",
      },
      BookOfBamboo: {
        redirectUrl: "/casino/worldcasino/BookOfBamboo",
        code: "bookofbamboo",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/BookOfBamboo.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Traveling Treasure Africa",
        providerName: "Only Play",
      },
      BookOfEye: {
        redirectUrl: "/casino/worldcasino/BookOfEye",
        code: "bookofeye",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/BookOfEye.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Book of Eye",
        providerName: "Only Play",
      },
      chervonakalyna: {
        redirectUrl: "/casino/worldcasino/chervonakalyna",
        code: "chervonakalyna",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/chervonakalyna.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Chervona Kalyna",
        providerName: "Only Play",
      },
      F777Fighter: {
        redirectUrl: "/casino/worldcasino/F777Fighter",
        code: "fighter",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/F777Fighter.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "F777 Fighter",
        providerName: "Only Play",
      },
    },
    slide8: {
      FruityBook: {
        redirectUrl: "/casino/worldcasino/FruityBook",
        code: "fruitybook",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/FruityBook.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruity Book",
        providerName: "Only Play",
      },
      GoldenClover: {
        redirectUrl: "/casino/worldcasino/GoldenClover",
        code: "goldenclover",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/GoldenClover.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Golden Clover",
        providerName: "Only Play",
      },
      HOtAndSpicy: {
        redirectUrl: "/casino/worldcasino/HOtAndSpicy",
        code: "hotandspicy",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/HOtAndSpicy.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Hot and Spicy",
        providerName: "Only Play",
      },
      HOtAndSpicyjackpot: {
        redirectUrl: "/casino/worldcasino/HOtAndSpicyjackpot",
        code: "hotandspicyjackpot",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/HOtAndSpicyjackpot.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Hot and Spicy Jackpot",
        providerName: "Only Play",
      },
      IggyRacing: {
        redirectUrl: "/casino/worldcasino/IggyRacing",
        code: "iggyracing",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/IggyRacing.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Iggy Racingt",
        providerName: "Only Play",
      },
      Incason: {
        redirectUrl: "/casino/worldcasino/Incason",
        code: "incason",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/Incason.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Incason",
        providerName: "Only Play",
      },
      Jackpotter: {
        redirectUrl: "/casino/worldcasino/Jackpotter",
        code: "jackpotter",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/Jackpotter.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Potter",
        providerName: "Only Play",
      },
      jackpotterdeluxe: {
        redirectUrl: "/casino/worldcasino/jackpotterdeluxe",
        code: "jackpotterdeluxe",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/jackpotterdeluxe.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Potter Deluxe",
        providerName: "Only Play",
      },
      jackpotterxmas: {
        redirectUrl: "/casino/worldcasino/jackpotterxmas",
        code: "jackpotterxmas",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/jackpotterxmas.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Potter X-MAS",
        providerName: "Only Play",
      },
      JokerCoin: {
        redirectUrl: "/casino/worldcasino/JokerCoin",
        code: "jokerscoins",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JokerCoin.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Joker Coin",
        providerName: "Only Play",
      },
      JokerCoinXmas: {
        redirectUrl: "/casino/worldcasino/JokerCoinXmas",
        code: "jokerscoinsxmas",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JokerCoinXmas.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Joker Coin Xmas",
        providerName: "Only Play",
      },
      JuicyCrush: {
        redirectUrl: "/casino/worldcasino/JuicyCrush",
        code: "juicycrush",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JuicyCrush.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Juicy Crush",
        providerName: "Only Play",
      },
    },
    slide9: {
      JungleGold: {
        redirectUrl: "/casino/worldcasino/JungleGold",
        code: "junglegold",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/JungleGold.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jungle Gold",
        providerName: "Only Play",
      },
      limbocat: {
        redirectUrl: "/casino/worldcasino/limbocat",
        code: "limbocat",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/limbocat.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Limbo Cat",
        providerName: "Only Play",
      },
      LuckyClover: {
        redirectUrl: "/casino/worldcasino/LuckyClover",
        code: "luckyclover",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyClover.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Clover",
        providerName: "Only Play",
      },
      LuckyOcean: {
        redirectUrl: "/casino/worldcasino/LuckyOcean",
        code: "luckyocean",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyOcean.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Ocean",
        providerName: "Only Play",
      },
      LuckyPunch: {
        redirectUrl: "/casino/worldcasino/LuckyPunch",
        code: "luckypunch",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyPunch.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Punch",
        providerName: "Only Play",
      },
      LuckyTanks: {
        redirectUrl: "/casino/worldcasino/LuckyTanks",
        code: "luckytanks",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/LuckyTanks.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Lucky Tanks",
        providerName: "Only Play",
      },
      Mythsofbastet: {
        redirectUrl: "/casino/worldcasino/Mythsofbastet",
        code: "mythsofbastet",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/Mythsofbastet.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Myths of Bastet",
        providerName: "Only Play",
      },
      RoyalKitties: {
        redirectUrl: "/casino/worldcasino/RoyalKitties",
        code: "royalkitties",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        imgUrl: "./images/slots/oneplay/RoyalKitties.jpg",
        alt: "",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Royal Kitties",
        providerName: "Only Play",
      },

      Strike777: {
        redirectUrl: "/casino/ezugi/Strike777",
        code: "1100031",
        casino: "ezugi",
        imgUrl: "./images/slots/777strike.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "777 Strike",
        providerName: "Red Tiger",
      },
      Trillionaire: {
        redirectUrl: "/casino/ezugi/Trillionaire",
        code: "1100047",
        casino: "ezugi",
        imgUrl: "./images/slots/Trillionaire.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Trillionaire",
        providerName: "Red Tiger",
      },
      TreasureMine: {
        redirectUrl: "/casino/ezugi/TreasureMine",
        code: "1100048",
        casino: "ezugi",
        imgUrl: "./images/slots/Treasure-Mine.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Treasure Mine",
        providerName: "Red Tiger",
      },
    },
    slide10: {
      ThorVengeance: {
        redirectUrl: "/casino/ezugi/ThorVengeance",
        code: "1100049",
        casino: "ezugi",
        imgUrl: "./images/slots/Thor's-Vengeance.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Thor's Vengeance",
        providerName: "Red Tiger",
      },
      RegalStreak: {
        redirectUrl: "/casino/ezugi/RegalStreak",
        code: "1100050",
        casino: "ezugi",
        imgUrl: "./images/slots/Regal-Streak.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Regal Streak",
        providerName: "Red Tiger",
      },
      RegalBeasts: {
        redirectUrl: "/casino/ezugi/RegalBeasts",
        code: "1100051",
        casino: "ezugi",
        imgUrl: "./images/slots/Regal-Beasts.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Regal Beasts",
        providerName: "Red Tiger",
      },
      ReelKeeper: {
        redirectUrl: "/casino/ezugi/ReelKeeper",
        code: "1100053",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel-Keeper.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel Keeper",
        providerName: "Red Tiger",
      },
      Victorious: {
        redirectUrl: "/casino/ezugi/Victorious",
        code: "1100123",
        casino: "ezugi",
        imgUrl: "./images/slots/Victorious.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Victorious",
        providerName: "NetEnt",
      },
      Warlords: {
        redirectUrl: "/casino/ezugi/Warlords",
        code: "1100125",
        casino: "ezugi",
        imgUrl: "./images/slots/Warlords-Crystal-of-Power.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Warlords Crystal of Power",
        providerName: "NetEnt",
      },
      WildWater: {
        redirectUrl: "/casino/ezugi/WildWater",
        code: "1100127",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Water.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Water",
        providerName: "NetEnt",
      },
      WildWater: {
        redirectUrl: "/casino/ezugi/WildWater",
        code: "1100127",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Water.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Water",
        providerName: "NetEnt",
      },
      LegendofAthena: {
        redirectUrl: "/casino/ezugi/LegendofAthena",
        code: "1100154",
        casino: "ezugi",
        imgUrl: "./images/slots/Legend-of-Athena.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Legend of Athena",
        providerName: "Red Tiger",
      },
    },

    name: "All Games",
  },

  // onetouchtab: {
  //   slide1: {
  //     BeatsEX: {
  //       redirectUrl: "/casino/worldcasino/BeatsEX",
  //       code: "2001",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/BeatsEX.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Beats Ex",
  //       providerName: "One Touch",
  //     },
  //     BonusTrack: {
  //       redirectUrl: "/casino/worldcasino/BonusTrack",
  //       code: "231551",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/BonusTrack.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Bonus Track",
  //       providerName: "One Touch",
  //     },
  //     CashGalaxy: {
  //       redirectUrl: "/casino/worldcasino/CashGalaxy",
  //       code: "2020101564",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/CashGalaxy.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Cash Galaxy",
  //       providerName: "One Touch",
  //     },
  //     FireCombo: {
  //       redirectUrl: "/casino/worldcasino/FireCombo",
  //       code: "20210428",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/FireCombo.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Fire Combo",
  //       providerName: "One Touch",
  //     },
  //     GeniesSecret: {
  //       redirectUrl: "/casino/worldcasino/GeniesSecret",
  //       code: "20210413",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/GeniesSecret.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Genie's Secret",
  //       providerName: "One Touch",
  //     },
  //     GodHand: {
  //       redirectUrl: "/casino/worldcasino/GodHand",
  //       code: "70045",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/GodHand.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "God Hand",
  //       providerName: "One Touch",
  //     },
  //     GOldenShisha: {
  //       redirectUrl: "/casino/worldcasino/GOldenShisha",
  //       code: "202107192",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/GOldenShisha.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Golden Shisha",
  //       providerName: "One Touch",
  //     },
  //     GrandHeist: {
  //       redirectUrl: "/casino/worldcasino/GrandHeist",
  //       code: "20210913",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/GrandHeist.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Grand Heist",
  //       providerName: "One Touch",
  //     },
  //     GrandHeistFeatureBuy: {
  //       redirectUrl: "/casino/worldcasino/GrandHeistFeatureBuy",
  //       code: "20220601",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/GrandHeistFeatureBuy.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Grand Heist Feature Buy",
  //       providerName: "One Touch",
  //     },
  //     InfinityTOwer: {
  //       redirectUrl: "/casino/worldcasino/InfinityTOwer",
  //       code: "202105310",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/InfinityTOwer.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Infinity Tower",
  //       providerName: "One Touch",
  //     },
  //     jewelrycats: {
  //       redirectUrl: "/casino/worldcasino/jewelrycats",
  //       code: "1042021",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/jewelrycats.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Jewelery Cats",
  //       providerName: "One Touch",
  //     },
  //     KenoRush: {
  //       redirectUrl: "/casino/worldcasino/KenoRush",
  //       code: "20245145",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/KenoRush.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Keno Rush",
  //       providerName: "One Touch",
  //     },
  //   },
  //   slide2: {
  //     OTMikoFestival: {
  //       redirectUrl: "/casino/worldcasino/OTMikoFestival",
  //       code: "202107193",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/OT_Miko_Festival.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Miko Festival",
  //       providerName: "One Touch",
  //     },
  //     kingdragontiger: {
  //       redirectUrl: "/casino/worldcasino/kingdragontiger",
  //       code: "270421",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/kingdragontiger.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "King Dragon Tiger",
  //       providerName: "One Touch",
  //     },
  //     LadyFortuna: {
  //       redirectUrl: "/casino/worldcasino/LadyFortuna",
  //       code: "202104133",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/LadyFortuna.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Lady Fortuna",
  //       providerName: "One Touch",
  //     },
  //     PipsQuest: {
  //       redirectUrl: "/casino/worldcasino/PipsQuest",
  //       code: "50113",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/PipsQuest.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Pips Quest",
  //       providerName: "One Touch",
  //     },
  //     QueenOfGloryLegancy: {
  //       redirectUrl: "/casino/worldcasino/QueenOfGloryLegancy",
  //       code: "9200218",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/QueenOfGloryLegancy.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Queen Of Glory Legancy",
  //       providerName: "One Touch",
  //     },
  //     RaidenMedalBurst: {
  //       redirectUrl: "/casino/worldcasino/RaidenMedalBurst",
  //       code: "20210914",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/RaidenMedalBurst.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Raiden Medal Burst",
  //       providerName: "One Touch",
  //     },
  //     SeaTreasure: {
  //       redirectUrl: "/casino/worldcasino/SeaTreasure",
  //       code: "202105311",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/SeaTreasure.jpg",
  //       //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Sea Treasure",
  //       providerName: "One Touch",
  //     },
  //     SnackBlast: {
  //       redirectUrl: "/casino/worldcasino/SnackBlast",
  //       code: "20210419",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/SnackBlast.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Snack Blast",
  //       providerName: "One Touch",
  //     },
  //     StoneGaze: {
  //       redirectUrl: "/casino/worldcasino/StoneGaze",
  //       code: "2022133",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/StoneGaze.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Stone Gaze",
  //       providerName: "One Touch",
  //     },
  //     TeenPattiOT: {
  //       redirectUrl: "/casino/worldcasino/TeenPattiOT",
  //       code: "31032022",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/TeenPatti.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Teen Patti",
  //       providerName: "One Touch",
  //     },
  //     TeenPatti2020OT: {
  //       redirectUrl: "/casino/worldcasino/TeenPatti2020OT",
  //       code: "31032021",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/TeenPatti2020.jpg",
  //       alt: "",
  //       homeUrl: pageUrlGenerator("/Slots"), //
  //       name: "Teen Patti 2020.jpg",
  //       providerName: "One Touch",
  //     },
  //     TravelingTreasureAfrica: {
  //       redirectUrl: "/casino/worldcasino/TravelingTreasureAfrica",
  //       code: "9110511",
  //       casino: "wco",
  //       provider: "OT",
  //       homeUrl: "",
  //       imgUrl: "./images/slots/onetouch/TravelingTreasureAfrica.jpg",
  //       alt: "",

  //       name: "Traveling Treasure Africa",
  //       providerName: "One Touch",
  //     },
  //   },

  //   name: "One Touch",
  // },

  // supernowatab: {
  //   slide1: {
  //     cards32Supernowa: {
  //       redirectUrl: "/casino/worldcasino/cards32Supernowa",
  //       code: "C32",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/32Cards.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "32 Crads ",
  //       providerName: "Supernowa",
  //     },
  //     ClassicAndarBaharSupernowa: {
  //       redirectUrl: "/casino/worldcasino/ClassicAndarBaharSupernowa",
  //       code: "AB",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/ClassicAndarBahar.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Classic Andar Bahar",
  //       providerName: "Supernowa",
  //     },
  //     ARWSupernowa: {
  //       redirectUrl: "/casino/worldcasino/ARWSupernowa",
  //       code: "ARW",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/AkbarRomeoWalter.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: " Akbar Romeo Walter",
  //       providerName: "Supernowa",
  //     },
  //     BaccaratSupernowa: {
  //       redirectUrl: "/casino/worldcasino/BaccaratSupernowa",
  //       code: "BAC",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/BaccaratSN.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: " Baccarat",
  //       providerName: "Supernowa",
  //     },
  //     Lucky7Supernowa: {
  //       redirectUrl: "/casino/worldcasino/Lucky7Supernowa",
  //       code: "UD7",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/Lucky7.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: " Lucky 7",
  //       providerName: "Supernowa",
  //     },
  //     RNG3PlayerTeenPattiSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNG3PlayerTeenPattiSupernowa",
  //       code: "V3TP",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNG3PlayerTeenPatti.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG 3 Player Teen Patti",
  //       providerName: "Supernowa",
  //     },
  //     RNG32CardsSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNG32CardsSupernowa",
  //       code: "VC32",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNG32Cards.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG 32 Cards",
  //       providerName: "Supernowa",
  //     },
  //     RNGAkbarromeowalterSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGAkbarromeowalterSupernowa",
  //       code: "VARW",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGAkbarromeowalter.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Akbar Romeo Walter",
  //       providerName: "Supernowa",
  //     },
  //     RNGCasinoQueenSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGCasinoQueenSupernowa",
  //       code: "RCQ",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGCasinoQueen.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Casino Queen",
  //       providerName: "Supernowa",
  //     },
  //     RNGDragonTigerSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGDragonTigerSupernowa",
  //       code: "VDT",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGDragonTiger.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Dragon Tiger",
  //       providerName: "Supernowa",
  //     },
  //     RNGJokerSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGJokerSupernowa",
  //       code: "VJKR",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGJoker.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Joker",
  //       providerName: "Supernowa",
  //     },
  //     RNGKingRacSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGKingRacSupernowa",
  //       code: "VCR",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGKingRace.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG King Race",
  //       providerName: "Supernowa",
  //     },
  //   },

  //   slide2: {
  //     RNGTeenPatti3Supernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGTeenPatti3Supernowa",
  //       code: "VTP",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGTeenPatti3.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Teen Patti",
  //       providerName: "Supernowa",
  //     },
  //     RNGTeenPatti2020Supernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGTeenPatti2020Supernowa",
  //       code: "VTP20",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGTeenPatti2020.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Teen Patti 2020",
  //       providerName: "Supernowa",
  //     },
  //     RNGWorliMatkaSupernowa: {
  //       redirectUrl: "/casino/worldcasino/RNGWorliMatkaSupernowa",
  //       code: "VWM",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/RNGWorliMatka.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "RNG Worli Matka",
  //       providerName: "Supernowa",
  //     },
  //     TeenPatti202Supernowa: {
  //       redirectUrl: "/casino/worldcasino/TeenPatti202Supernowa",
  //       code: "VTP20",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/Teenpatti2020.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Teen Patti 2020",
  //       providerName: "Supernowa",
  //     },
  //     TeenPattiSupernowa: {
  //       redirectUrl: "/casino/worldcasino/TeenPattiSupernowa",
  //       code: "TP",
  //       casino: "wco",
  //       provider: "SN",
  //       imgUrl: "./images/slots/supernowa/teenpatti1.jpg",
  //       homeUrl: pageUrlGenerator("/Slots"),
  //       name: "Teen Patti",
  //       providerName: "Supernowa",
  //     },
  //   },

  //   name: "Supernowa",
  // },

  prgmaticplaytab: {
    slide1: {
      CoffeeWild: {
        redirectUrl: "/casino/pp-coffee-wild",
        imgUrl: "./images/slots/pragmaticplay/CoffeeWild.jpg",
        name: "Coffee Wild",
        providerName: "Pragmatic Play",
      },
      DownTheRails: {
        redirectUrl: "/casino/pp-down-the-rails",
        code: "422",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DownTheRails.jpg",
        name: "Down The Rails",
        providerName: "Pragmatic Play",
      },
      FLoatingDragonMegaways: {
        redirectUrl: "/casino/pp-floating-dragon-megaways",
        code: "401",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FLoatingDragonMegaways.jpg",
        name: "FLoating Dragon Megaways",
        providerName: "Pragmatic Play",
      },
      GreedyWOlf: {
        redirectUrl: "/casino/pp-greedy-wolf",
        code: "411",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GreedyWOlf.jpg",
        name: "Greedy WOlf",
        providerName: "Pragmatic Play",
      },
      MagicMoneyMaze: {
        redirectUrl: "/casino/pp-magic-money-maze",
        code: "404",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MagicMoneyMaze.jpg",
        name: "Magic Money Maze",
        providerName: "Pragmatic Play",
      },
      bigbasssplash: {
        redirectUrl: "/casino/pp-big-bass-splash",
        code: "438",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_bigbasssplash.jpg",
        name: "Big Bass Splash",
        providerName: "Pragmatic Play",
      },
      bombbonanza: {
        redirectUrl: "/casino/pp-bomb-bonanza",
        code: "405",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_bombbonanza.jpg",
        name: "Bomb Bonanza",
        providerName: "Pragmatic Play",
      },
      cheekyemperor: {
        redirectUrl: "/casino/pp-cheeky-emperor",
        code: "414",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_cheekyemperor.jpg",
        name: "Cheeky Emperor",
        providerName: "Pragmatic Play",
      },
      cleocatra: {
        redirectUrl: "/casino/pp-cleocatra",
        code: "434",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_cleocatra.jpg",
        name: "Cleocatra",
        providerName: "Pragmatic Play",
      },
      cosmiccash: {
        redirectUrl: "/casino/pp-cosmic-cash",
        code: "435",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_cosmiccash.jpg",
        name: "Cosmic Cash",
        providerName: "Pragmatic Play",
      },
      mahjongpanda: {
        redirectUrl: "/casino/pp-mahjong-panda",
        code: "427",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_mahjongpanda.jpg",
        name: "Mahjong Panda",
        providerName: "Pragmatic Play",
      },
      firehot5: {
        redirectUrl: "/casino/pp-fire-hot-5",
        code: "436",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot5.jpg",
        name: "Fire Hot 5",
        providerName: "Pragmatic Play",
      },
    },
    slide2: {
      octobeerfortunes: {
        redirectUrl: "/casino/pp-octobeer-fortunes",
        code: "403",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_octobeerfortunes.jpg",
        name: "Octobeer Fortunes",
        providerName: "Pragmatic Play",
      },
      firehot20: {
        redirectUrl: "/casino/pp-fire-hot-20",
        code: "426",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot20.jpg",
        name: "Fire Hot 20",
        providerName: "Pragmatic Play",
      },
      Qweenofgod: {
        redirectUrl: "/casino/pp-queen-of-gods",
        code: "433",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_Qweenofgod.jpg",
        name: "Queen of Gods",
        providerName: "Pragmatic Play",
      },
      firehot40: {
        redirectUrl: "/casino/pp-fire-hot-40",
        code: "425",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot40.jpg",
        name: "Fire Hot 40",
        providerName: "Pragmatic Play",
      },
      sugarrush: {
        redirectUrl: "/casino/pp-sugar-rush",
        code: "415",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_sugar_rush.jpg",
        name: "Sugar Rush",
        providerName: "Pragmatic Play",
      },
      firehot100: {
        redirectUrl: "/casino/pp-fire-hot-100",
        code: "413",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_firehot100.jpg",
        name: "Fire Hot 100",
        providerName: "Pragmatic Play",
      },
      thegreatstickup: {
        redirectUrl: "/casino/pp-the-great-stick-up",
        code: "225",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_thegreatstick-up.jpg",
        name: "The Great Stick-Up",
        providerName: "Pragmatic Play",
      },
      gorillamayhem: {
        redirectUrl: "/casino/pp-gorilla-mayhem",
        code: "402",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_gorillamayhem.jpg",
        name: "Gorilla Mayhem",
        providerName: "Pragmatic Play",
      },
      ZombieCarnival: {
        redirectUrl: "/casino/pp-zombie-carnival",
        code: "102",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_ZombieCarnival.jpg",
        name: "Zombie Carnival",
        providerName: "Pragmatic Play",
      },
      hottoburnextreme: {
        redirectUrl: "/casino/pp-hot-to-burn-extreme",
        code: "412",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_hottoburnextreme.jpg",
        name: "Hot to Burn Extreme",
        providerName: "Pragmatic Play",
      },
      ShiningHot5: {
        redirectUrl: "/casino/pp-shining-hot-5",
        code: "421",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot5.jpg",
        name: "Shining Hot 5",
        providerName: "Pragmatic Play",
      },
      KoiPond: {
        redirectUrl: "/casino/pp-koi-pond",
        code: "107",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_KoiPond.jpg",
        name: "Koi Pond",
        providerName: "Pragmatic Play",
      },
    },
    slide3: {
      ShiningHot20: {
        redirectUrl: "/casino/pp-shining-hot-20",
        code: "424",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot20.jpg",
        name: "Shining Hot 20",
        providerName: "Pragmatic Play",
      },
      littlegem: {
        redirectUrl: "/casino/pp-little-gem-hold-and-spin",
        code: "439",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/pgp_littlegem.jpg",
        name: "Little Gem Hold & Spin",
        providerName: "Pragmatic Play",
      },
      ShiningHot40: {
        redirectUrl: "/casino/pp-shining-hot-40",
        code: "428",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot40.jpg",
        name: "Shining Hot 40",
        providerName: "Pragmatic Play",
      },
      ShiningHot100: {
        redirectUrl: "/casino/pp-shining-hot-100",
        code: "430",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ShiningHot100.jpg",
        name: "Shining Hot 100",
        providerName: "Pragmatic Play",
      },
      tropicaltiki: {
        redirectUrl: "/casino/pp-tropical-tiki",
        code: "431",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/tropicaltiki.jpg",
        name: "Tropical Tiki",
        providerName: "Pragmatic Play",
      },
      WildmanSuperBOnanza: {
        redirectUrl: "/casino/pp-wildman-super-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildmanSuperBOnanza.jpg",
        name: "Wildman Super Bonanza",
        providerName: "Pragmatic Play",
      },
      LionsGold5: {
        redirectUrl: "/casino/pp-5-lions-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/5LionsGold.jpg",
        name: "5 Lions Gold",
        providerName: "Pragmatic Play",
      },
      piggies7: {
        redirectUrl: "/casino/pp-7-piggies",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/7piggies.jpg",
        name: "7 piggies",
        providerName: "Pragmatic Play",
      },
      dragon888: {
        redirectUrl: "/casino/pp-888-dragons",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/888dragon.jpg",
        name: "888 dragon",
        providerName: "Pragmatic Play",
      },
      aladinandthesrcerer: {
        redirectUrl: "/casino/pp-aladdin-and-the-sorcerer",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/aladinandthesrcerer.jpg",
        name: "Aladin and the sorcerer",
        providerName: "Pragmatic Play",
      },
      ancientegypt: {
        redirectUrl: "/casino/pp-ancient-egypt",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ancientegypt.jpg",
        name: "Ancient egypt",
        providerName: "Pragmatic Play",
      },
      AncientEgyptClassic: {
        redirectUrl: "/casino/pp-ancient-egypt-classic",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AncientEgyptClassic.jpg",
        name: "Ancient Egypt Classic",
        providerName: "Pragmatic Play",
      },
    },
    slide4: {
      Asgard: {
        redirectUrl: "/casino/pp-asgard",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Asgard.jpg",
        name: "Asgard",
        providerName: "Pragmatic Play",
      },
      AztecGems: {
        redirectUrl: "/casino/pp-aztec-gems",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AztecGems.jpg",
        name: "Aztec Gems",
        providerName: "Pragmatic Play",
      },
      BuffaloKing: {
        redirectUrl: "/casino/pp-buffalo-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BuffaloKing.jpg",
        name: "Buffalo King",
        providerName: "Pragmatic Play",
      },
      CaishensCash: {
        redirectUrl: "/casino/pp-caishens-cash",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Caishen'sCash.jpg",
        name: "Caishen'sCash",
        providerName: "Pragmatic Play",
      },
      ChilliHeat: {
        redirectUrl: "/casino/pp-chilli-heat",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChilliHeat.jpg",
        name: "Chilli Heat",
        providerName: "Pragmatic Play",
      },
      DaVincisTreasure: {
        redirectUrl: "/casino/pp-da-vincis-treasure",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DaVinci'sTreasure.jpg",
        name: "DaVinci's Treasure",
        providerName: "Pragmatic Play",
      },
      diamondsareforever3line: {
        redirectUrl: "/casino/pp-diamonds-are-forever-3-lines",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/diamondsareforever3line.jpg",
        name: "Diamonds are forever 3 line",
        providerName: "Pragmatic Play",
      },
      EgyptianFortunes: {
        redirectUrl: "/casino/pp-egyptian-fortunes",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EgyptianFortunes.jpg",
        name: "Egyptian Fortunes",
        providerName: "Pragmatic Play",
      },
      ExtraJuicy: {
        redirectUrl: "/casino/pp-extra-juicy",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ExtraJuicy.jpg",
        name: "Extra Juicy ",
        providerName: "Pragmatic Play",
      },
      FairytaleFortune: {
        redirectUrl: "/casino/pp-fairytale-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FairytaleFortune.jpg",
        name: "Fairytale Fortune ",
        providerName: "Pragmatic Play",
      },
      Fire88: {
        redirectUrl: "/casino/pp-fire-88",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Fire88.jpg",
        name: "Fire 88",
        providerName: "Pragmatic Play",
      },
      FireStrike: {
        redirectUrl: "/casino/pp-fire-strike",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FireStrike.jpg",
        name: "Fire Strike",
        providerName: "Pragmatic Play",
      },
    },
    slide5: {
      GoldenPig: {
        redirectUrl: "/casino/pp-golden-pig",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldenPig.jpg",
        name: "Golden Pig",
        providerName: "Pragmatic Play",
      },
      GoldRush: {
        redirectUrl: "/casino/pp-gold-rush",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldRush.jpg",
        name: "Gold Rush",
        providerName: "Pragmatic Play",
      },
      Greekgods: {
        redirectUrl: "/casino/pp-greek-gods",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Greekgods.jpg",
        name: "Greek gods",
        providerName: "Pragmatic Play",
      },
      HerculesandPegasus: {
        redirectUrl: "/casino/pp-hercules-and-pegasus",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HerculesandPegasus.jpg",
        name: "Hercules and Pegasus",
        providerName: "Pragmatic Play",
      },
      HoneyHoneyHoney: {
        redirectUrl: "/casino/pp-honey-honey-honey",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HoneyHoneyHoney.jpg",
        name: "Honey Honey Honey",
        providerName: "Pragmatic Play",
      },
      HotChilli: {
        redirectUrl: "/casino/pp-hot-chilli",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HotChilli.jpg",
        name: "Hot Chilli",
        providerName: "Pragmatic Play",
      },
      JadeButterfly: {
        redirectUrl: "/casino/pp-jade-butterfly",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JadeButterfly.jpg",
        name: "Jade Butterfly",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheAztecTreasure: {
        redirectUrl: "/casino/pp-john-hunter-and-the-aztec-treasure",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/JohnHunterandtheAztecTreasure.jpg",
        name: "John Hunter and the Aztec Treasure",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheTomboftheScarabQueen: {
        redirectUrl: "/casino/pp-john-hunter-and-the-tomb-of-the-scarab-queen",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/JohnHunterandtheTomboftheScarabQueen.jpg",
        name: "John Hunter and the Tomb of the ScarabQueen",
        providerName: "Pragmatic Play",
      },
      JokersJewels: {
        redirectUrl: "/casino/pp-jokers-jewels",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JokersJewels.jpg",
        name: "Jokers Jewels",
        providerName: "Pragmatic Play",
      },
      leprechaun: {
        redirectUrl: "/casino/pp-leprechaun-carol",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/leprechaun.jpg",
        name: "Leprechaun",
        providerName: "Pragmatic Play",
      },
      LeprechaunSong: {
        redirectUrl: "/casino/pp-leprechaun-song",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LeprechaunSong.jpg",
        name: "Leprechaun Song",
        providerName: "Pragmatic Play",
      },
    },
    slide6: {
      LuckyNewYear: {
        redirectUrl: "/casino/pp-lucky-new-year",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyNewYear.jpg",
        name: "Lucky New Year",
        providerName: "Pragmatic Play",
      },
      MadameDestiny: {
        redirectUrl: "/casino/pp-madame-destiny",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MadameDestiny.jpg",
        name: "Madame Destiny",
        providerName: "Pragmatic Play",
      },
      MagicJourney: {
        redirectUrl: "/casino/pp-magic-journey",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MagicJourney.jpg",
        name: "Magic Journey",
        providerName: "Pragmatic Play",
      },
      Monkeymadness: {
        redirectUrl: "/casino/pp-monkey-madness",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Monkeymadness.jpg",
        name: "Monkey madness",
        providerName: "Pragmatic Play",
      },
      MonkeyWarrior: {
        redirectUrl: "/casino/pp-monkey-warrior",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MonkeyWarrior.jpg",
        name: "Monkey Warrior",
        providerName: "Pragmatic Play",
      },
      Mustanggold: {
        redirectUrl: "/casino/pp-mustang-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Mustanggold.jpg",
        name: "Mustang gold",
        providerName: "Pragmatic Play",
      },
      PekingLuck: {
        redirectUrl: "/casino/pp-peking-luck",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PekingLuck.jpg",
        name: "Peking Luck",
        providerName: "Pragmatic Play",
      },
      PirateGold: {
        redirectUrl: "/casino/pp-pirate-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PirateGold.jpg",
        name: "Pirate Gold",
        providerName: "Pragmatic Play",
      },
      ReleasetheKraken: {
        redirectUrl: "/casino/pp-release-the-kraken",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ReleasetheKraken.jpg",
        name: "Release the Kraken",
        providerName: "Pragmatic Play",
      },
      Safariking: {
        redirectUrl: "/casino/pp-safari-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Safariking.jpg",
        name: "Safari king",
        providerName: "Pragmatic Play",
      },
      Santa: {
        redirectUrl: "/casino/pp-santa",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Santa.jpg",
        name: "Santa",
        providerName: "Pragmatic Play",
      },
      Super7: {
        redirectUrl: "/casino/pp-super-7s",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Super7.jpg",
        name: "Super 7",
        providerName: "Pragmatic Play",
      },
    },
    slide7: {
      SuperJoker: {
        redirectUrl: "/casino/pp-super-joker",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SuperJoker.jpg",
        name: "Super Joker",
        providerName: "Pragmatic Play",
      },
      SweetBonanza: {
        redirectUrl: "/casino/pp-sweet-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SweetBonanza.jpg",
        name: "Sweet Bonanza",
        providerName: "Pragmatic Play",
      },
      Sweetbonanzaxmas: {
        redirectUrl: "/casino/pp-sweet-bonanza-xmas",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Sweetbonanzaxmas.jpg",
        name: "Sweet bonanza xmas",
        providerName: "Pragmatic Play",
      },
      TheDogHouse: {
        redirectUrl: "/casino/pp-the-dog-house",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheDogHouse.jpg",
        name: "The Dog House",
        providerName: "Pragmatic Play",
      },
      TheGreatChickenEscape: {
        redirectUrl: "/casino/pp-the-great-chicken-escape",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheGreatChickenEscape.jpg",
        name: "The Great Chicken Escape",
        providerName: "Pragmatic Play",
      },
      TreasureHorse: {
        redirectUrl: "/casino/pp-treasure-horse",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TreasureHorse.jpg",
        name: "Treasure Horse",
        providerName: "Pragmatic Play",
      },
      TreeofRiches: {
        redirectUrl: "/casino/pp-tree-of-riches",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TreeofRiches.jpg",
        name: "Tree of Riches",
        providerName: "Pragmatic Play",
      },
      tripledragons: {
        redirectUrl: "/casino/pp-triple-dragons",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/tripledragons.jpg",
        name: "Triple dragons",
        providerName: "Pragmatic Play",
      },
      TripleTigers: {
        redirectUrl: "/casino/pp-triple-tigers",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TripleTigers.jpg",
        name: "Triple Tigers",
        providerName: "Pragmatic Play",
      },
      VampiresVSWolves: {
        redirectUrl: "/casino/pp-vampires-vs-wolves",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/VampiresVSWolves.jpg",
        name: "Vampires VS Wolves",
        providerName: "Pragmatic Play",
      },
      Vegasnights: {
        redirectUrl: "/casino/pp-vegas-nights",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Vegasnights.jpg",
        name: "Vegas nights",
        providerName: "Pragmatic Play",
      },
      WildGladiator: {
        redirectUrl: "/casino/pp-wild-gladiator",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildGladiator.jpg",
        name: "Wild Gladiator",
        providerName: "Pragmatic Play",
      },
      WildPixies: {
        redirectUrl: "/casino/pp-wild-pixies",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildPixies.jpg",
        name: "Wild Pixies",
        providerName: "Pragmatic Play",
      },
    },
    slide8: {
      geniewishemin3: {
        redirectUrl: "/casino/pp-3-genie-wishes",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/3geniewishe-min.jpg",
        name: "3 Geniewishe",
        providerName: "Pragmatic Play",
      },
      KingdomsBattleofRedCliffsmin3: {
        redirectUrl: "/casino/pp-3-kingdoms-battle-of-red-cliffs",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/3KingdomsBattleofRedCliffs-min.jpg",
        name: "3 Kingdoms BattleofRedCliffs",
        providerName: "Pragmatic Play",
      },
      monkeys7min: {
        redirectUrl: "/casino/pp-7-monkeys",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/7monkeys-min.jpg",
        name: "7 monkeys",
        providerName: "Pragmatic Play",
      },
      Gold888: {
        redirectUrl: "/casino/pp-888-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/888Gold-min.jpg",
        name: "Gold 888",
        providerName: "Pragmatic Play",
      },
      Beowulf: {
        redirectUrl: "/casino/pp-beowulf",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Beowulf.jpg",
        name: "Beowulf",
        providerName: "Pragmatic Play",
      },
      Devils13: {
        redirectUrl: "/casino/pp-devils-13",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Devils13.jpg",
        name: "Devils 13",
        providerName: "Pragmatic Play",
      },
      DiamondStrike: {
        redirectUrl: "/casino/pp-diamond-strike",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DiamondStrike.jpg",
        name: "Diamond Strike",
        providerName: "Pragmatic Play",
      },
      DragonKingdom: {
        redirectUrl: "/casino/pp-dragon-kingdom",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragonKingdom.jpg",
        name: "Dragon Kingdom",
        providerName: "Pragmatic Play",
      },
      dwarvengolddeluxe: {
        redirectUrl: "/casino/pp-dwarven-gold-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/dwarvengolddeluxe.jpg",
        name: "Dwarven gold deluxe",
        providerName: "Pragmatic Play",
      },
      GoldTrain: {
        redirectUrl: "/casino/pp-gold-train",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldTrain.jpg",
        name: "Gold Train",
        providerName: "Pragmatic Play",
      },
      Greatreef: {
        redirectUrl: "/casino/pp-great-reef",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Greatreef.jpg",
        name: "Great reef",
        providerName: "Pragmatic Play",
      },
      HerculesSonOfZeus: {
        redirectUrl: "/casino/pp-hercules-son-of-zeus",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HerculesSonOfZeus.jpg",
        name: "Hercules Son Of Zeus",
        providerName: "Pragmatic Play",
      },
      Hotsafari: {
        redirectUrl: "/casino/pp-hot-safari",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Hotsafari.jpg",
        name: "Hot safari",
        providerName: "Pragmatic Play",
      },
      irishcharms: {
        redirectUrl: "/casino/pp-irish-charms",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/irishcharms.jpg",
        name: "Irish charms",
        providerName: "Pragmatic Play",
      },
      JourneytotheWest: {
        redirectUrl: "/casino/pp-journey-to-the-west",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JourneytotheWest.jpg",
        name: "Journey to the West",
        providerName: "Pragmatic Play",
      },
    },
    slide9: {
      ladygodiva: {
        redirectUrl: "/casino/pp-lady-godiva",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ladygodiva.jpg",
        name: "Lady godiva",
        providerName: "Pragmatic Play",
      },
      LuckyDragons: {
        redirectUrl: "/casino/pp-lucky-dragons",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyDragons.jpg",
        name: "Lucky Dragons",
        providerName: "Pragmatic Play",
      },
      MightKong: {
        redirectUrl: "/casino/pp-mighty-kong",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MightKong.jpg",
        name: "Mighty Kong",
        providerName: "Pragmatic Play",
      },
      MoneyRoll: {
        redirectUrl: "/casino/pp-money-roll",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MoneyRoll.jpg",
        name: "Money Roll",
        providerName: "Pragmatic Play",
      },
      PandasFortune: {
        redirectUrl: "/casino/pp-pandas-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PandasFortune.jpg",
        name: "Pandas Fortune",
        providerName: "Pragmatic Play",
      },
      Aztechking: {
        redirectUrl: "/casino/pp-aztec-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Aztechking.jpg",
        name: "Aztec king",
        providerName: "Pragmatic Play",
      },
      Barnfestival: {
        redirectUrl: "/casino/pp-barn-festival",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Barnfestival.jpg",
        name: "Barn festival",
        providerName: "Pragmatic Play",
      },
      bullfiesta: {
        redirectUrl: "/casino/pp-bull-fiesta",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/bullfiesta.jpg",
        name: "Bull fiesta",
        providerName: "Pragmatic Play",
      },
      Chickenchase: {
        redirectUrl: "/casino/pp-chicken-chase",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Chickenchase.jpg",
        name: "Chicken chase",
        providerName: "Pragmatic Play",
      },
      colossalcashzone: {
        redirectUrl: "/casino/pp-colossal-cash-zone",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/colossalcashzone.jpg",
        name: "Colossal cash zone",
        providerName: "Pragmatic Play",
      },
      Discolady: {
        redirectUrl: "/casino/pp-disco-lady",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Discolady.jpg",
        name: "Discolady",
        providerName: "Pragmatic Play",
      },
      Drillthatgold: {
        redirectUrl: "/casino/pp-drill-that-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Drillthatgold.jpg",
        name: "Drill that gold",
        providerName: "Pragmatic Play",
      },
    },
    slide10: {
      eyeofcleopatra: {
        redirectUrl: "/casino/pp-eye-of-cleopatra",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/eyeofcleopatra.jpg",
        name: "Eye of cleopatra",
        providerName: "Pragmatic Play",
      },

      Goblinheistpowernudge: {
        redirectUrl: "/casino/pp-goblin-heist-powernudge",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Goblinheistpowernudge.jpg",
        name: "Goblin heist powernudge",
        providerName: "Pragmatic Play",
      },
      northguardians: {
        redirectUrl: "/casino/pp-north-guardians",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/northguardians.jpg",
        name: "North guardians",
        providerName: "Pragmatic Play",
      },
      mightyofRa: {
        redirectUrl: "/casino/pp-might-of-ra",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/mightyofRa.jpg",
        name: "Might of Ra",
        providerName: "Pragmatic Play",
      },
      Rainbowgold: {
        redirectUrl: "/casino/pp-rainbow-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Rainbowgold.jpg",
        name: "Rainbow gold",
        providerName: "Pragmatic Play",
      },
      snakesandladders: {
        redirectUrl: "/casino/pp-snakes-and-ladders-megadice",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/snakesandladders.jpg",
        name: "Snakes and ladders",
        providerName: "Pragmatic Play",
      },
      spaceman: {
        redirectUrl: "/casino/pp-spaceman",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/spaceman.jpg",
        name: "Spaceman",
        providerName: "Pragmatic Play",
      },
      Spiritofadventure: {
        redirectUrl: "/casino/pp-spirit-of-adventure",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Spiritofadventure.jpg",
        name: "Spirit of adventure",
        providerName: "Pragmatic Play",
      },
      Theultimate5: {
        redirectUrl: "/casino/pp-the-ultimate-5",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Theultimate5.jpg",
        name: "The ultimate 5",
        providerName: "Pragmatic Play",
      },
      Tictactake: {
        redirectUrl: "/casino/pp-tic-tac-take",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Tictactake.jpg",
        name: "Tic tac take",
        providerName: "Pragmatic Play",
      },
      wildbeachparty: {
        redirectUrl: "/casino/pp-wild-beach-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/wildbeachparty.jpg",
        name: "wild beach party",
        providerName: "Pragmatic Play",
      },
      wildwestgoldmegaways: {
        redirectUrl: "/casino/pp-wild-west-gold-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/wildwestgoldmegaways.jpg",
        name: "wild west Goldmegaways",
        providerName: "Pragmatic Play",
      },
    },
    slide11: {
      LionsDance5: {
        redirectUrl: "/casino/pp-5-lions-dance",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/5LionsDance.jpg",
        name: "5 Lions Dances",
        providerName: "Pragmatic Play",
      },
      LionsMegaways5: {
        redirectUrl: "/casino/pp-5-lions-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/5LionsMegaways.jpg",
        name: "5 Lions Megaways",
        providerName: "Pragmatic Play",
      },
      AmazingMoneyMachine: {
        redirectUrl: "/casino/pp-amazing-money-machine",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AmazingMoneyMachine.jpg",
        name: "Amazing Money Machine",
        providerName: "Pragmatic Play",
      },
      AztecGemsDeluxe: {
        redirectUrl: "/casino/pp-aztec-gems-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AztecGemsDeluxe.jpg",
        name: "Aztec Gems Deluxe",
        providerName: "Pragmatic Play",
      },
      AztecKingMegaways: {
        redirectUrl: "/casino/pp-aztec-king-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/AztecKingMegaways.jpg",
        name: "Aztec King Megaways",
        providerName: "Pragmatic Play",
      },
      BigBassBonanza: {
        redirectUrl: "/casino/pp-big-bass-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BigBassBonanza.jpg",
        name: "Big Bass Bonanza",
        providerName: "Pragmatic Play",
      },
      BigBassBonanzaMegaways: {
        redirectUrl: "/casino/pp-big-bass-bonanza-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BigBassBonanzaMegaways.jpg",
        name: "Big Bass Bonanza Megaways",
        providerName: "Pragmatic Play",
      },
      BigJuan: {
        redirectUrl: "/casino/pp-big-juan",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BigJuan.jpg",
        name: "Big Juan",
        providerName: "Pragmatic Play",
      },
      BonanzaGold: {
        redirectUrl: "/casino/pp-bonanza-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BonanzaGold.jpg",
        name: "Bonanza Gold",
        providerName: "Pragmatic Play",
      },
      BookofFallen: {
        redirectUrl: "/casino/pp-book-of-the-fallen",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BookofFallen.jpg",
        name: "Book of Fallen",
        providerName: "Pragmatic Play",
      },
      BookofKingdoms: {
        redirectUrl: "/casino/pp-book-of-kingdoms",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BookofKingdoms.jpg",
        name: "Book of Kingdoms",
        providerName: "Pragmatic Play",
      },
      BountyGold: {
        redirectUrl: "/casino/pp-bounty-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BountyGold.jpg",
        name: "Bounty Gold",
        providerName: "Pragmatic Play",
      },
    },
    slide12: {
      BroncoSpirit: {
        redirectUrl: "/casino/pp-bronco-spirit",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BroncoSpirit.jpg",
        name: "Bronco Spirit",
        providerName: "Pragmatic Play",
      },
      BubblePop: {
        redirectUrl: "/casino/pp-bubble-pop",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BubblePop.jpg",
        name: "Bubble Pop",
        providerName: "Pragmatic Play",
      },
      BuffaloKingMegaways: {
        redirectUrl: "/casino/pp-buffalo-king-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/BuffaloKingMegaways.jpg",
        name: "Buffalo King Megaways",
        providerName: "Pragmatic Play",
      },
      CandyVillage: {
        redirectUrl: "/casino/pp-candy-village",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CandyVillage.jpg",
        name: "Candy Village",
        providerName: "Pragmatic Play",
      },
      CashBonanza: {
        redirectUrl: "/casino/pp-cash-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CashBonanza.jpg",
        name: "Cash Bonanza",
        providerName: "Pragmatic Play",
      },
      CashElevator: {
        redirectUrl: "/casino/pp-cash-elevator",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CashElevator.jpg",
        name: "Cash Elevator",
        providerName: "Pragmatic Play",
      },
      ChickenDrop: {
        redirectUrl: "/casino/pp-chicken-drop",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChickenDrop.jpg",
        name: "Chicken Drop",
        providerName: "Pragmatic Play",
      },
      ChilliHeatMegaways: {
        redirectUrl: "/casino/pp-chilli-heat-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChilliHeatMegaways.jpg",
        name: "Chilli Heat Megaways",
        providerName: "Pragmatic Play",
      },
      ChristmasBigBassBonanza: {
        redirectUrl: "/casino/pp-christmas-big-bass-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChristmasBigBassBonanza.jpg",
        name: "Christmas BigBass Bonanza",
        providerName: "Pragmatic Play",
      },
      ChristmasCarolMegaways: {
        redirectUrl: "/casino/pp-christmas-carol-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ChristmasCarolMegaways.jpg",
        name: "Christmas Carol Megaways",
        providerName: "Pragmatic Play",
      },
      CongoCash: {
        redirectUrl: "/casino/pp-congo-cash",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CongoCash.jpg",
        name: "Congo Cash",
        providerName: "Pragmatic Play",
      },
      CowboysGold: {
        redirectUrl: "/casino/pp-cowboys-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CowboysGold.jpg",
        name: "Cowboys Gold",
        providerName: "Pragmatic Play",
      },
    },
    slide13: {
      CrystalCavernsMegaways: {
        redirectUrl: "/casino/pp-crystal-caverns-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CrystalCavernsMegaways.jpg",
        name: "Crystal Caverns Megaways",
        providerName: "Pragmatic Play",
      },
      CurseoftheWerewolfMegaways: {
        redirectUrl: "/casino/pp-curse-of-the-werewolf-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/CurseoftheWerewolf-Megaways.jpg",
        name: "Curse of the Werewolf-Megaways",
        providerName: "Pragmatic Play",
      },
      DanceParty: {
        redirectUrl: "/casino/pp-dance-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DanceParty.jpg",
        name: "Dance Party",
        providerName: "Pragmatic Play",
      },
      DayofDead: {
        redirectUrl: "/casino/pp-day-of-dead",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DayofDead.jpg",
        name: "Day of Dead",
        providerName: "Pragmatic Play",
      },
      DragoJewelsofFortune: {
        redirectUrl: "/casino/pp-drago-jewels-of-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragoJewelsofFortune.jpg",
        name: "Drago Jewels of Fortune",
        providerName: "Pragmatic Play",
      },
      DragonHotHoldandSpin: {
        redirectUrl: "/casino/pp-dragon-hot-hold-and-spin",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragonHotHoldandSpin.jpg",
        name: "DragonHot HoldandSpin",
        providerName: "Pragmatic Play",
      },
      DragonKingdomEyesofFire: {
        redirectUrl: "/casino/pp-dragon-kingdom-eyes-of-fire",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/DragonKingdomEyesofFire.jpg",
        name: "Dragon Kingdom EyesofFire",
        providerName: "Pragmatic Play",
      },
      ElementalGemsMegaways: {
        redirectUrl: "/casino/pp-elemental-gems-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ElementalGemsMegaways.jpg",
        name: "Elemental Gems Megaways",
        providerName: "Pragmatic Play",
      },
      EmeraldKing: {
        redirectUrl: "/casino/pp-emerald-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmeraldKing.jpg",
        name: "Emerald King",
        providerName: "Pragmatic Play",
      },
      EmeraldKingRainbowRoad: {
        redirectUrl: "/casino/pp-emerald-king-rainbow-road",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmeraldKingRainbowRoad.jpg",
        name: "Emerald King RainbowRoad",
        providerName: "Pragmatic Play",
      },
      EmperorCaishen: {
        redirectUrl: "/casino/pp-emperor-caishen",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmperorCaishen.jpg",
        name: "Emperor Caishen",
        providerName: "Pragmatic Play",
      },
      EmptytheBank: {
        redirectUrl: "/casino/pp-empty-the-bank",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EmptytheBank.jpg",
        name: "Empty the Bank",
        providerName: "Pragmatic Play",
      },
    },
    slide14: {
      ExtraJuicyMegaways: {
        redirectUrl: "/casino/pp-extra-juicy-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ExtraJuicyMegaways.jpg",
        name: "Extra Juicy Megaways",
        providerName: "Pragmatic Play",
      },
      EyeoftheStorm: {
        redirectUrl: "/casino/pp-eye-of-the-storm",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/EyeoftheStorm.jpg",
        name: "Eye of the Storm",
        providerName: "Pragmatic Play",
      },
      FishinReels: {
        redirectUrl: "/casino/pp-fishin-reels",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FishinReels.jpg",
        name: "Fishin Reels",
        providerName: "Pragmatic Play",
      },
      FloatingDragon: {
        redirectUrl: "/casino/pp-floating-dragon",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FloatingDragon.jpg",
        name: "Floating Dragon",
        providerName: "Pragmatic Play",
      },
      FortuneofGiza: {
        redirectUrl: "/casino/pp-fortune-of-giza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FortuneofGiza.jpg",
        name: "Fortune of Giza",
        providerName: "Pragmatic Play",
      },
      FruitParty: {
        redirectUrl: "/casino/pp-fruit-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FruitParty.jpg",
        name: "Fruit Party",
        providerName: "Pragmatic Play",
      },
      FruitParty2: {
        redirectUrl: "/casino/pp-fruit-party-2",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FruitParty2.jpg",
        name: "Fruit Party 2",
        providerName: "Pragmatic Play",
      },
      FruitRainbow: {
        redirectUrl: "/casino/pp-fruit-rainbow",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FruitRainbow.jpg",
        name: "Fruit Rainbow",
        providerName: "Pragmatic Play",
      },
      FuFuFu: {
        redirectUrl: "/casino/pp-fu-fu-fu",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/FuFuFu.jpg",
        name: "Fu Fu Fu",
        providerName: "Pragmatic Play",
      },
      GatesofOlympus: {
        redirectUrl: "/casino/pp-gates-of-olympus",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GatesofOlympus.jpg",
        name: "Gates of Olympus",
        providerName: "Pragmatic Play",
      },
      GatesofValhalla: {
        redirectUrl: "/casino/pp-gates-of-valhalla",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GatesofValhalla.jpg",
        name: "Gates of Valhalla",
        providerName: "Pragmatic Play",
      },
      GemsBonanza: {
        redirectUrl: "/casino/pp-gems-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GemsBonanza.jpg",
        name: "Gems Bonanza",
        providerName: "Pragmatic Play",
      },
    },
    slide15: {
      GoldenBeauty: {
        redirectUrl: "/casino/pp-golden-beauty",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldenBeauty.jpg",
        name: "Golden Beauty",
        providerName: "Pragmatic Play",
      },
      GoldenOx: {
        redirectUrl: "/casino/pp-golden-ox",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldenOx.jpg",
        name: "Golden Ox",
        providerName: "Pragmatic Play",
      },
      GoldParty: {
        redirectUrl: "/casino/pp-gold-party",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GoldParty.jpg",
        name: "Gold Party",
        providerName: "Pragmatic Play",
      },
      GreatRhinoDeluxe: {
        redirectUrl: "/casino/pp-great-rhino-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GreatRhinoDeluxe.jpg",
        name: "Great Rhino Deluxe",
        providerName: "Pragmatic Play",
      },
      GreatRhinoMegaways: {
        redirectUrl: "/casino/pp-great-rhino-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/GreatRhinoMegaways.jpg",
        name: "Great Rhino Megaways",
        providerName: "Pragmatic Play",
      },
      HeartofRio: {
        redirectUrl: "/casino/pp-heart-of-rio",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HeartofRio.jpg",
        name: "Heart of Rio",
        providerName: "Pragmatic Play",
      },
      HockeyAttack: {
        redirectUrl: "/casino/pp-hockey-attack",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HockeyAttack.jpg",
        name: "Hockey Attack",
        providerName: "Pragmatic Play",
      },
      HokkaidoWolf: {
        redirectUrl: "/casino/pp-hokkaido-wolf",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HokkaidoWolf.jpg",
        name: "Hokkaido Wolf",
        providerName: "Pragmatic Play",
      },
      HotFiesta: {
        redirectUrl: "/casino/pp-hot-fiesta",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HotFiesta.jpg",
        name: "Hot Fiesta",
        providerName: "Pragmatic Play",
      },
      HottoBurn: {
        redirectUrl: "/casino/pp-hot-to-burn",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HottoBurn.jpg",
        name: "Hot to Burn",
        providerName: "Pragmatic Play",
      },
      HottoBurnHoldandSpin: {
        redirectUrl: "/casino/pp-hot-to-burn-hold-and-spin",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/HottoBurnHoldandSpin.jpg",
        name: "Hot to Burn HoldandSpin",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheBookofTut: {
        redirectUrl: "/casino/pp-john-hunter-and-the-book-of-tut",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JohnHunterandtheBookofTut.jpg",
        name: "John Hunter and the Book of Tut",
        providerName: "Pragmatic Play",
      },
    },
    slide16: {
      JohnHunterandtheMayanGods: {
        redirectUrl: "/casino/pp-john-hunter-and-the-mayan-gods",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JohnHunterandtheMayanGods.jpg",
        name: "John Hunter and the MayanGods",
        providerName: "Pragmatic Play",
      },
      JohnHunterandtheQuestforBermudaRiches: {
        redirectUrl: "/casino/pp-john-hunter-and-the-quest-for-bermuda-riches",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl:
          "./images/slots/pragmaticplay/JohnHunterandtheQuestforBermudaRiches.jpg",
        name: "John Hunter and the Quest for BermudaRiches",
        providerName: "Pragmatic Play",
      },
      JokerKing: {
        redirectUrl: "/casino/pp-joker-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JokerKing.jpg",
        name: "Joker King",
        providerName: "Pragmatic Play",
      },
      JuicyFruits: {
        redirectUrl: "/casino/pp-juicy-fruits",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JuicyFruits.jpg",
        name: "Juicy Fruits",
        providerName: "Pragmatic Play",
      },
      JungleGorilla: {
        redirectUrl: "/casino/pp-jungle-gorilla",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/JungleGorilla.jpg",
        name: "Jungle Gorilla",
        providerName: "Pragmatic Play",
      },
      LuckyDragonBall: {
        redirectUrl: "/casino/pp-lucky-dragon-ball",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyDragonBall.jpg",
        name: "Lucky DragonBall",
        providerName: "Pragmatic Play",
      },
      LuckyGraceAndCharm: {
        redirectUrl: "/casino/pp-lucky-grace-and-charm",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyGraceAndCharm.jpg",
        name: "Lucky Grace And Charm",
        providerName: "Pragmatic Play",
      },
      LuckyLightning: {
        redirectUrl: "/casino/pp-lucky-lightning",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyLightning.jpg",
        name: "Lucky Lightning",
        providerName: "Pragmatic Play",
      },
      LuckyNewYearTigerTreasures: {
        redirectUrl: "/casino/pp-lucky-new-year-tiger-treasures",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/LuckyNewYearTigerTreasures.jpg",
        name: "Lucky NewYear Tiger Treasures",
        providerName: "Pragmatic Play",
      },
      MadameDestinyMegaways: {
        redirectUrl: "/casino/pp-madame-destiny-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MadameDestinyMegaways.jpg",
        name: "Madame Destiny Megaways",
        providerName: "Pragmatic Play",
      },
      MagiciansSecrets: {
        redirectUrl: "/casino/pp-magicians-secrets",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Magician'sSecrets.jpg",
        name: "Magician's Secrets",
        providerName: "Pragmatic Play",
      },
      MasterJoker: {
        redirectUrl: "/casino/pp-master-joker",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MasterJoker.jpg",
        name: "Master Joker",
        providerName: "Pragmatic Play",
      },
    },
    slide17: {
      MoneyMoneyMoney: {
        redirectUrl: "/casino/pp-money-money-money",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MoneyMoneyMoney.jpg",
        name: "Money Money Money",
        providerName: "Pragmatic Play",
      },
      Mysterious: {
        redirectUrl: "/casino/pp-mysterious",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Mysterious.jpg",
        name: "Mysterious",
        providerName: "Pragmatic Play",
      },
      MysteriousEgypt: {
        redirectUrl: "/casino/pp-mysterious-egypt",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MysteriousEgypt.jpg",
        name: "Mysterious Egypt",
        providerName: "Pragmatic Play",
      },
      MysticChief: {
        redirectUrl: "/casino/pp-mystic-chief",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/MysticChief.jpg",
        name: "Mystic Chief",
        providerName: "Pragmatic Play",
      },
      PandaFortune2: {
        redirectUrl: "/casino/pp-panda-fortune-2",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PandaFortune2.jpg",
        name: "Panda Fortune 2",
        providerName: "Pragmatic Play",
      },
      PhoenixForge: {
        redirectUrl: "/casino/pp-phoenix-forge",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Phoenix-Forge.jpg",
        name: "Phoenix Forge",
        providerName: "Pragmatic Play",
      },
      PiggyBankBills: {
        redirectUrl: "/casino/pp-piggy-bank-bills",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PiggyBankBills.jpg",
        name: "Piggy Bank Bills",
        providerName: "Pragmatic Play",
      },
      PirateGoldDeluxe: {
        redirectUrl: "/casino/pp-pirate-gold-deluxe",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PirateGoldDeluxe.jpg",
        name: "Pirate Gold Deluxe",
        providerName: "Pragmatic Play",
      },
      PowerofThorMegaways: {
        redirectUrl: "/casino/pp-power-of-thor-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PowerofThorMegaways.jpg",
        name: "Power of Thor Megaways",
        providerName: "Pragmatic Play",
      },
      PyramidBonanza: {
        redirectUrl: "/casino/pp-pyramid-bonanza",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PyramidBonanza.jpg",
        name: "Pyramid Bonanza",
        providerName: "Pragmatic Play",
      },
      PyramidKing: {
        redirectUrl: "/casino/pp-pyramid-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/PyramidKing.jpg",
        name: "Pyramid King",
        providerName: "Pragmatic Play",
      },
      RagingBull: {
        redirectUrl: "/casino/pp-raging-bull",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RagingBull.jpg",
        name: "Raging Bull",
        providerName: "Pragmatic Play",
      },
    },
    slide18: {
      ReturnoftheDead: {
        redirectUrl: "/casino/pp-return-of-the-dead",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ReturnoftheDead.jpg",
        name: "Return of the Dead",
        providerName: "Pragmatic Play",
      },
      RiseofGizaPowerNudge: {
        redirectUrl: "/casino/pp-rise-of-giza-powernudge",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RiseofGizaPowerNudge.jpg",
        name: "Rise of Giza PowerNudge",
        providerName: "Pragmatic Play",
      },
      RiseofSamurai: {
        redirectUrl: "/casino/pp-rise-of-samurai",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RiseofSamurai.jpg",
        name: "Rise of Samurai",
        providerName: "Pragmatic Play",
      },
      RiseofSamuraiMegaways: {
        redirectUrl: "/casino/pp-rise-of-samurai-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RiseofSamuraiMegaways.jpg",
        name: "Rise of Samurai Megaways",
        providerName: "Pragmatic Play",
      },
      RockVegas: {
        redirectUrl: "/casino/pp-rock-vegas",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/RockVegas.jpg",
        name: "Rock Vegas",
        providerName: "Pragmatic Play",
      },
      SantasWonderland: {
        redirectUrl: "/casino/pp-santas-wonderland",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/Santa'sWonderland.jpg",
        name: "Santa's Wonderland",
        providerName: "Pragmatic Play",
      },
      SmugglersCove: {
        redirectUrl: "/casino/pp-smugglers-cove",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SmugglersCove.jpg",
        name: "Smugglers Cove",
        providerName: "Pragmatic Play",
      },
      SpartanKing: {
        redirectUrl: "/casino/pp-spartan-king",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SpartanKing.jpg",
        name: "Spartan King",
        providerName: "Pragmatic Play",
      },
      StarBounty: {
        redirectUrl: "/casino/pp-star-bounty",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarBounty.jpg",
        name: "Star Bounty",
        providerName: "Pragmatic Play",
      },
      StarlightPrincess: {
        redirectUrl: "/casino/pp-starlight-princess",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarlightPrincess.jpg",
        name: "Starlight Princess",
        providerName: "Pragmatic Play",
      },
      StarPiratesCode: {
        redirectUrl: "/casino/pp-star-pirates-code",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarPiratesCode.jpg",
        name: "Star Pirates Code",
        providerName: "Pragmatic Play",
      },
      StarzMegaways: {
        redirectUrl: "/casino/pp-starz-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StarzMegaways.jpg",
        name: "Starz Megaways",
        providerName: "Pragmatic Play",
      },
    },
    slide19: {
      StreetRacer: {
        redirectUrl: "/casino/pp-street-racer",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/StreetRacer.jpg",
        name: "Street Racer",
        providerName: "Pragmatic Play",
      },
      SuperX: {
        redirectUrl: "/casino/pp-super-x",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/SuperX.jpg",
        name: "Super X",
        providerName: "Pragmatic Play",
      },
      TemujinTreasures: {
        redirectUrl: "/casino/pp-temujin-treasures",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TemujinTreasures.jpg",
        name: "Temujin Treasures",
        providerName: "Pragmatic Play",
      },
      TheDogHouseMegaways: {
        redirectUrl: "/casino/pp-the-dog-house-megaways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheDogHouseMegaways.jpg",
        name: "The Dog House Megaways",
        providerName: "Pragmatic Play",
      },
      TheDragonTiger: {
        redirectUrl: "/casino/pp-the-dragon-tiger",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheDragonTiger.jpg",
        name: "The Dragon Tiger",
        providerName: "Pragmatic Play",
      },
      TheHandofMidas: {
        redirectUrl: "/casino/pp-the-hand-of-midas",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheHandofMidas.jpg",
        name: "The Hand of Midas",
        providerName: "Pragmatic Play",
      },
      TheMagicCauldron: {
        redirectUrl: "/casino/pp-the-magic-cauldron",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheMagicCauldron.jpg",
        name: "The Magic Cauldron",
        providerName: "Pragmatic Play",
      },
      TheTigerWarrior: {
        redirectUrl: "/casino/pp-the-tiger-warrior",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheTigerWarrior.jpg",
        name: "The Tiger Warrior",
        providerName: "Pragmatic Play",
      },
      TheTweetyHouse: {
        redirectUrl: "/casino/pp-the-tweety-house",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheTweetyHouse.jpg",
        name: "The Tweety House",
        providerName: "Pragmatic Play",
      },
      TheWildMachine: {
        redirectUrl: "/casino/pp-the-wild-machine",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TheWildMachine.jpg",
        name: "The Wild Machine",
        providerName: "Pragmatic Play",
      },
      ThreeStarFortune: {
        redirectUrl: "/casino/pp-three-star-fortune",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/ThreeStarFortune.jpg",
        name: "Three Star Fortune",
        providerName: "Pragmatic Play",
      },
      TreasureWild: {
        redirectUrl: "/casino/pp-treasure-wild",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/TreasureWild.jpg",
        name: "Treasure Wild",
        providerName: "Pragmatic Play",
      },
    },
    slide20: {
      UltraBurn: {
        redirectUrl: "/casino/pp-ultra-burn",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/UltraBurn.jpg",
        name: "Ultra Burn",
        providerName: "Pragmatic Play",
      },
      UltraHoldandSpin: {
        redirectUrl: "/casino/pp-ultra-hold-and-spin",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/UltraHoldandSpin.jpg",
        name: "Ultra Hold and Spin",
        providerName: "Pragmatic Play",
      },
      VoodooMagic: {
        redirectUrl: "/casino/pp-voodoo-magic",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/VoodooMagic.jpg",
        name: "Voodoo Magic",
        providerName: "Pragmatic Play",
      },
      WildBooster: {
        redirectUrl: "/casino/pp-wild-booster",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildBooster.jpg",
        name: "Wild Booster",
        providerName: "Pragmatic Play",
      },
      WildDepths: {
        redirectUrl: "/casino/pp-wild-depths",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildDepths.jpg",
        name: "Wild Depths",
        providerName: "Pragmatic Play",
      },
      WildWalker: {
        redirectUrl: "/casino/pp-wild-walker",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildWalker.jpg",
        name: "Wild Walker",
        providerName: "Pragmatic Play",
      },
      WildWestGold: {
        redirectUrl: "/casino/pp-wild-west-gold",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildWestGold.jpg",
        name: "Wild West Gold",
        providerName: "Pragmatic Play",
      },
      WildWildRiches: {
        redirectUrl: "/casino/pp-wild-wild-riches",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/WildWildRiches.jpg",
        name: "Wild Wild Riches",
        providerName: "Pragmatic Play",
      },
      YumYumPowerways: {
        redirectUrl: "/casino/pp-yum-yum-powerways",
        code: "104",
        casino: "wco",
        provider: "PP",
        imgUrl: "./images/slots/pragmaticplay/YumYumPowerways.jpg",
        name: "Yum Yum Powerways",
        providerName: "Pragmatic Play",
      },
    },
    name: "Pragmatic Play",
  },
  //slotsPart1: {
  //
  //
  //
  //
  //
  //   slots6: {
  //     redirectUrl: "/casino/ezugi/Nights1000",
  //     imgUrl: "./images/slots/10001-nights.jpg",
  //     name: "10001 Nights",
  //   },
  //   slots7: {
  //     redirectUrl: "/casino/ezugi/CashUltimate",
  //     imgUrl: "./images/slots/cash_ultimate.jpg",
  //     name: "Cash Ultimate",
  //   },
  //   slots8: {
  //     redirectUrl: "/casino/ezugi/CashVolt",
  //     imgUrl: "./images/slots/cash_volt.jpg",
  //     name: "Cash Volt",
  //   },
  //
  //   slots11: {
  //     redirectUrl: "/casino/ezugi/Dynamiteriches",
  //     imgUrl: "./images/slots/dynamite_riches.jpg",
  //     name: "Dynamite Riches",
  //   },
  //   slots12: {
  //     redirectUrl: "/casino/ezugi/FortuneHouse",
  //     imgUrl: "./images/slots/fortunehouse.jpg",
  //     name: "Fotune House",
  //   },
  //   slots13: {
  //     redirectUrl: "/casino/ezugi/FruitShopMegaways",
  //     imgUrl: "./images/slots/Fruit_Shop_Megaways.jpg",
  //     name: "Fruit Shop Megaways",
  //   },
  //   slots14: {
  //     redirectUrl: "/casino/ezugi/GoldenCryptex",
  //     imgUrl: "./images/slots/Golden_Cryptex.jpg",
  //     name: "Golden Cryptex",
  //   },
  //   slots15: {
  //     redirectUrl: "/casino/ezugi/GoldenTsar",
  //     imgUrl: "./images/slots/Golden_Tsar.jpg",
  //     name: "Golden Tsar",
  //   },
  //   slots16: {
  //     redirectUrl: "/casino/ezugi/GorillaKingdom",
  //     imgUrl: "./images/slots/Gorilla_Kingdom.jpg",
  //     name: "Gorilla Kingdom",
  //   },
  // },
  // slotsPart2: {
  //   slots17: {
  //     redirectUrl: "/casino/ezugi/MystryReels",
  //     imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
  //     name: "Mystery Reels Megaways",
  //   },
  //   slots18: {
  //     redirectUrl: "/casino/ezugi/RageoftheSea",
  //     imgUrl: "./images/slots/Rage_Of_The_Seas.jpg",
  //     name: "Rage Of The Seas",
  //   },
  //   slots19: {
  //     redirectUrl: "/casino/ezugi/ReelKingMegaways",
  //     imgUrl: "./images/slots/Reel_King_Mega.jpg",
  //     name: "Reel King Mega",
  //   },
  //   slots20: {
  //     redirectUrl: "/casino/ezugi/TurnFortune",
  //     imgUrl: "./images/slots/Turn_Your_Fortune.jpg",
  //     name: "Turn Your Fortune",
  //   },
  //
  //   slots22: {
  //     redirectUrl: "/casino/ezugi/WillysChills",
  //     imgUrl: "./images/slots/Willys_Hot_Chillies.jpg",
  //     name: "Willys Hot Chillies",
  //   },
  //   slots23: {
  //     redirectUrl: "/casino/ezugi/ChineseTreasures",
  //     imgUrl: "./images/slots/Chinese-Treasures.jpg",
  //     name: "Chinese Treasures",
  //   },
  //
  //
  //
  //   slots28: {
  //     redirectUrl: "/casino/ezugi/EpicJourney",
  //     imgUrl: "./images/slots/Epic-Journey.jpg",
  //     name: "Epic Journey",
  //   },
  //   slots29: {
  //     redirectUrl: "/casino/ezugi/GodWealth",
  //     imgUrl: "./images/slots/God-Of-Wealth.jpg",
  //     name: "God Of Wealth",
  //   },
  //   slots30: {
  //     redirectUrl: "/casino/ezugi/LuckyFortune",
  //     imgUrl: "./images/slots/Lucky-Fortune-Cat.jpg",
  //     name: "Lucky Fortune Cat",
  //   },
  //   slots31: {
  //     redirectUrl: "/casino/ezugi/MagicGate",
  //     imgUrl: "./images/slots/Magic-Gate.jpg",
  //     name: "Magic Gate",
  //   },
  //   slots32: {
  //     redirectUrl: "/casino/ezugi/GoldenTemple",
  //     imgUrl: "./images/slots/Golden-Temple.jpg",
  //     name: "Golden Temple",
  //   },
  // },
  // slotsPart3: {
  //   slots33: {
  //     redirectUrl: "/casino/ezugi/PhoenixPower",
  //     imgUrl: "./images/slots/Phoenix-Fire-Power-Reels.jpg",
  //     name: "Phoenix Fire Power Reels",
  //   },
  //   slots34: {
  //     redirectUrl: "/casino/ezugi/GoldenOffer",
  //     imgUrl: "./images/slots/Golden-Offer.jpg",
  //     name: "Golden Offer",
  //   },
  //   slots35: {
  //     redirectUrl: "/casino/ezugi/RAsLegend",
  //     imgUrl: "./images/slots/RA's-Legend.jpg",
  //     name: "RA's Legend",
  //   },
  //
  //   slots37: {
  //     redirectUrl: "/casino/ezugi/RainbowJAcket",
  //     imgUrl: "./images/slots/Rainbow-Jackpots.jpg",
  //     name: "Rainbow Jackpots",
  //   },
  //   slots38: {
  //     redirectUrl: "/casino/ezugi/MegaPyramid",
  //     imgUrl: "./images/slots/Mega-Pyramid.jpg",
  //     name: "Mega Pyramid",
  //   },
  //   slots39: {
  //     redirectUrl: "/casino/ezugi/Jimi_Hendrix",
  //     imgUrl: "./images/slots/Jimi_Hendrix.jpg",
  //     name: "Jimi Hendrix",
  //   },
  //   slots40: {
  //     redirectUrl: "/casino/ezugi/Jumanji",
  //     imgUrl: "./images/slots/Jumanji.jpg",
  //     name: "Jumanji",
  //   },
  //   slots41: {
  //     redirectUrl: "/casino/ezugi/MotorheadVideoSlot",
  //     imgUrl: "./images/slots/Motorhead_Video_Slot.jpg",
  //     name: "Motorhead Video Slot",
  //   },
  //   slots42: {
  //     redirectUrl: "/casino/ezugi/Starburst",
  //     imgUrl: "./images/slots/Starburst.jpg",
  //     name: "Starburst",
  //   },
  //   slots43: {
  //     redirectUrl: "/casino/ezugi/WarofGod",
  //     imgUrl: "./images/slots/War-Of-Gods.jpg",
  //     name: "War Of Gods",
  //   },
  //
  //   slots45: {
  //     redirectUrl: "/casino/ezugi/ClusterPays",
  //     imgUrl: "./images/slots/Aloha-Cluster-Pays.jpg",
  //     name: "Aloha Cluster Pays",
  //   },
  //   slots46: {
  //     redirectUrl: "/casino/ezugi/AncientsBlessing",
  //     imgUrl: "./images/slots/Ancients-Blessing.jpg",
  //     name: "Ancients Blessing",
  //   },
  //   slots47: {
  //     redirectUrl: "/casino/ezugi/AurumCodex",
  //     imgUrl: "./images/slots/Aurum-Codex.jpg",
  //     name: "Aurum Codex",
  //   },
  //   slots48: {
  //     redirectUrl: "/casino/ezugi/GunsNRoses",
  //     imgUrl: "./images/slots/Guns_N_Roses.jpg",
  //     name: "Guns N Roses",
  //   },
  //   slots49: {
  //     redirectUrl: "/casino/ezugi/BorisandBoo",
  //     imgUrl: "./images/slots/Betty-Boris-and-Boo.jpg",
  //     name: "Betty Boris and Boo",
  //   },
  // },
  // slotsPart4: {
  //   slots50: {
  //     redirectUrl: "/casino/ezugi/WildWest",
  //     imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.jpg",
  //     name: "Wild West The Great Train Heist",
  //   },
  //   slots51: {
  //     redirectUrl: "/casino/ezugi/SteamTower",
  //     imgUrl: "./images/slots/Steam-Tower.jpg",
  //     name: "Steam Tower",
  //   },
  //   slots52: {
  //     redirectUrl: "/casino/ezugi/ZeusLightning",
  //     imgUrl: "./images/slots/Zeus-Lightning-Power-Reels.jpg",
  //     name: "Zeus Lightning Power Reels",
  //   },
  //   slots53: {
  //     redirectUrl: "/casino/ezugi/WingsofRa",
  //     imgUrl: "./images/slots/Wings-of-Ra.jpg",
  //     name: "Wings of Ra",
  //   },
  //
  //   slots60: {
  //     redirectUrl: "/casino/ezugi/RainbowJackpotsPowerLines",
  //     imgUrl: "./images/slots/Rainbow-Jackpots-Power-Lines.jpg",
  //     name: "Rainbow Jackpots Power Lines",
  //   },
  //
  //   slots62: {
  //     redirectUrl: "/casino/ezugi/JingleBells",
  //     imgUrl: "./images/slots/Jingle-Bells.jpg",
  //     name: "Jingle Bells",
  //   },
  //   slots63: {
  //     redirectUrl: "/casino/ezugi/JackinaPot",
  //     imgUrl: "./images/slots/Jack-in-a-Pot.jpg",
  //     name: "Jack in a Pot",
  //   },
  //   slots64: {
  //     redirectUrl: "/casino/ezugi/FiveStar",
  //     imgUrl: "./images/slots/Five-Star.jpg",
  //     name: "Five Star",
  //   },
  //   slots65: {
  //     redirectUrl: "/casino/ezugi/BountyRaid",
  //     imgUrl: "./images/slots/Bounty-Raid.jpg",
  //     name: "Bounty Raid",
  //   },
  //   slots66: {
  //     redirectUrl: "/casino/ezugi/MysteryReels2",
  //     imgUrl: "./images/slots/Mystery-Reels.jpg",
  //     name: "Mystery Reels",
  //   },
  // },
  // slotsPart5: {
  //   slots67: {
  //     redirectUrl: "/casino/ezugi/DiamondBlitz",
  //     imgUrl: "./images/slots/Diamond-Blitz.jpg",
  //     name: "Diamond Blitz",
  //   },
  //   slots68: {
  //     redirectUrl: "/casino/ezugi/DragonFireMegaways",
  //     imgUrl: "./images/slots/Dragon's-Fire-Megaways.jpg",
  //     name: "Dragon's Fire Megaways",
  //   },
  //   slots69: {
  //     redirectUrl: "/casino/ezugi/Rome",
  //     imgUrl: "./images/slots/Rome-The-Golden-Age.jpg",
  //     name: "Rome-The Golden Age",
  //   },
  //   slots70: {
  //     redirectUrl: "/casino/ezugi/ReelRush",
  //     imgUrl: "./images/slots/Reel-Rush.jpg",
  //     name: "Reel Rush",
  //   },
  //   slots71: {
  //     redirectUrl: "/casino/ezugi/BloodSuckers",
  //     imgUrl: "./images/slots/Blood-Suckers.jpg",
  //     name: "Blood Suckers",
  //   },
  //   slots72: {
  //     redirectUrl: "/casino/ezugi/BloodSuckers2",
  //     imgUrl: "./images/slots/Blood-Suckers-2.jpg",
  //     name: "Blood Suckers 2",
  //   },
  //
  //   slots74: {
  //     redirectUrl: "/casino/ezugi/ButterflyStaxx",
  //     imgUrl: "./images/slots/Butterfly-Staxx.jpg",
  //     name: "Butterfly Staxx",
  //   },
  //   slots75: {
  //     redirectUrl: "/casino/ezugi/ButterflyStaxx2",
  //     imgUrl: "./images/slots/Butterfly-Staxx-2.jpg",
  //     name: "Butterfly Staxx 2",
  //   },
  //
  //
  //   slots78: {
  //     redirectUrl: "/casino/ezugi/JingleBellsPowerReels",
  //     imgUrl: "./images/slots/Jingle-Bells-Power-Reels.jpg",
  //     name: "Jingle Bells Power Reels",
  //   },
  //   slots79: {
  //     redirectUrl: "/casino/ezugi/Dazzle",
  //     imgUrl: "./images/slots/Dazzle-Me.jpg",
  //     name: "Dazzle Me",
  //   },
  //   slots80: {
  //     redirectUrl: "/casino/ezugi/JackHammer",
  //     imgUrl: "./images/slots/Jack-Hammer.jpg",
  //     name: "Jack Hammer",
  //   },
  //   slots81: {
  //     redirectUrl: "/casino/ezugi/JackHammer2",
  //     imgUrl: "./images/slots/Jack-Hammer-2-Fishy-Business.jpg",
  //     name: "Jack Hammer 2 Fishy Business",
  //   },
  //
  // },
  // slotsPart6: {
  //
  //   slots85: {
  //     redirectUrl: "/casino/ezugi/JackandtheBeanstalk",
  //     imgUrl: "./images/slots/Jack-and-the-Beanstalk.jpg",
  //     name: "Jack and the Beanstalk",
  //   },
  //
  //   slots87: {
  //     redirectUrl: "/casino/ezugi/GordonRamsayHellsKitchen",
  //     imgUrl: "./images/slots/Gordon_Ramsay_Hells_Kitchen.jpg",
  //     name: "Gordon Ramsay Hells Kitchen",
  //   },
  //   slots88: {
  //     redirectUrl: "/casino/ezugi/Elements",
  //     imgUrl: "./images/slots/Elements-The-Awakening.jpg",
  //     name: "Elements The Awakening",
  //   },
  //   slots89: {
  //     redirectUrl: "/casino/ezugi/GonzosQuest",
  //     imgUrl: "./images/slots/Gonzos_Quest.jpg",
  //     name: "Gonzos Quest",
  //   },
  //
  //
  //
  //   slots93: {
  //     redirectUrl: "/casino/ezugi/DoubleStacks",
  //     imgUrl: "./images/slots/Double-Stacks.jpg",
  //     name: "Double Stacks",
  //   },
  //   slots94: {
  //     redirectUrl: "/casino/ezugi/FairytaleLegends",
  //     imgUrl: "./images/slots/Fairytale-Legends-Red-Riding-Hood.jpg",
  //     name: "Fairytale Legends Red Riding Hood",
  //   },
  //   slots95: {
  //     redirectUrl: "/casino/ezugi/Flowers",
  //     imgUrl: "./images/slots/Flowers.jpg",
  //     name: "Flowers",
  //   },
  //   slots96: {
  //     redirectUrl: "/casino/ezugi/FruitShopChristmasEdition",
  //     imgUrl: "./images/slots/Fruit-Shop-Christmas-Edition.jpg",
  //     name: "Fruit Shop Christmas Edition",
  //   },
  //   slots97: {
  //     redirectUrl: "/casino/ezugi/Hotline",
  //     imgUrl: "./images/slots/Hotline.jpg",
  //     name: "Hotline",
  //   },
  //   slots98: {
  //     redirectUrl: "/casino/ezugi/Hotline2",
  //     imgUrl: "./images/slots/Hotline-2.jpg",
  //     name: "Hotline 2",
  //   },
  //   slots99: {
  //     redirectUrl: "/casino/ezugi/TheInvisibleMan",
  //     imgUrl: "./images/slots/The_Invisible_Man.jpg",
  //     name: "The Invisible Man",
  //   },
  //   slots100: {
  //     redirectUrl: "/casino/ezugi/IrishPotLuck",
  //     imgUrl: "./images/slots/Irish-Pot-Luck.jpg",
  //     name: "Irish Pot Luck",
  //   },
  // },
  // slotsPart7: {
  //   slots101: {
  //     redirectUrl: "/casino/ezugi/FruitSpin",
  //     imgUrl: "./images/slots/Fruit-Spin.jpg",
  //     name: "Fruit Spin",
  //   },
  //   slots102: {
  //     redirectUrl: "/casino/ezugi/JungleSpirit",
  //     imgUrl: "./images/slots/Jungle-Spirit-Call-of-the-Wild.jpg",
  //     name: "Jungle Spirit Call of the Wild",
  //   },
  //   slots103: {
  //     redirectUrl: "/casino/ezugi/KoiPrincess",
  //     imgUrl: "./images/slots/Koi-Princess.jpg",
  //     name: "Koi Princess",
  //   },
  //   slots104: {
  //     redirectUrl: "/casino/ezugi/DriveMultiplierMayhem",
  //     imgUrl: "./images/slots/Drive-Multiplier-Mayhem.jpg",
  //     name: "Drive Multiplier Mayhem",
  //   },
  //   slots105: {
  //     redirectUrl: "/casino/ezugi/RageoftheSeas",
  //     imgUrl: "./images/slots/Rage-of-the-Seas.jpg",
  //     name: "Rage of the Seas",
  //   },
  //   slots106: {
  //     redirectUrl: "/casino/ezugi/RichesofMidgard",
  //     imgUrl: "./images/slots/Riches-of-Midgard-Land-and-Expand.jpg",
  //     name: "Riches of Midgard Land and Expand",
  //   },
  //   slots107: {
  //     redirectUrl: "/casino/ezugi/ScruffyDuck",
  //     imgUrl: "./images/slots/Scruffy-Duck.jpg",
  //     name: "Scruffy Duck",
  //   },
  //   slots108: {
  //     redirectUrl: "/casino/ezugi/SecretoftheStones",
  //     imgUrl: "./images/slots/Secret-of-the-Stones.jpg",
  //     name: "Secret of the Stones",
  //   },
  //   slots109: {
  //     redirectUrl: "/casino/ezugi/SecretsofChristmas",
  //     imgUrl: "./images/slots/Secrets-of-Christmas.jpg",
  //     name: "Secret of Christmas",
  //   },
  //   slots110: {
  //     redirectUrl: "/casino/ezugi/SerengetiKings",
  //     imgUrl: "./images/slots/Serengeti-Kings.jpg",
  //     name: "Serengeti Kings",
  //   },
  //   slots111: {
  //     redirectUrl: "/casino/ezugi/SpaceWars",
  //     imgUrl: "./images/slots/Space-Wars.jpg",
  //     name: "Space Wars",
  //   },
  //
  //   slots116: {
  //     redirectUrl: "/casino/ezugi/Squad4",
  //     imgUrl: "./images/slots/4-Squad.jpg",
  //     name: "4 Squad",
  //   },
  //   slots117: {
  //     redirectUrl: "/casino/ezugi/Families5",
  //     imgUrl: "./images/slots/5-Families.jpg",
  //     name: "5 Families",
  //   },
  // },
  // slotsPart8: {
  //
  //   slots131: {
  //     redirectUrl: "/casino/ezugi/GemsGoneWild",
  //     imgUrl: "./images/slots/Gems-Gone-Wild.jpg",
  //     name: "Gems Gone Wild",
  //   },
  //   slots132: {
  //     redirectUrl: "/casino/ezugi/GoldenLeprechaunMegaways",
  //     imgUrl: "./images/slots/Golden-Leprechaun-Megaways.jpg",
  //     name: "GoldenLeprechaun Megaways",
  //   },
  //   slots132: {
  //     redirectUrl: "/casino/ezugi/HoardofPoseidon",
  //     imgUrl: "./images/slots/Hoard-of-Poseidon.jpg",
  //     name: "Hoard of Poseidon",
  //   },
  //   slots133: {
  //     redirectUrl: "/casino/ezugi/JewelScarabs",
  //     imgUrl: "./images/slots/Jewel-Scarabs.jpg",
  //     name: "Jewel Scarabs",
  //   },
  //
  // },
};
