import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class FairPlay extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main">
            <div className="container">
              <h2 class="sectionTitle"> Fair Play</h2>
              <div className="fairplay">
                <p>
                  Cinco888 treats all Players equally and respectfully. Cinco888
                  Provide Fair Odds and Fair winnings. Fair competition,
                  respect, friendship, team spirit, equality, sport without
                  doping, respect for written and unwritten rules such as
                  integrity, solidarity, tolerance, care, excellence and joy,
                  are the building blocks of fair play that can be experienced
                  and learnt both on and off the field.
                </p>
              </div>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default FairPlay;
